import Vue from 'vue'
import { Module } from 'vuex'
import { ApiError, FunctionList, FunctionListService } from '@/__generated__'

const functionList: Module<any, any> = {
  namespaced: true,
  state: () => ({
    functionLists: Array<FunctionList>(),
    functionListsIds: Array<number>()
  }),
  mutations: {
    setFunctionLists (state, functionLists: FunctionList[]) {
      state.functionLists = functionLists.sort((a, b) => (a.functionListId ?? 0) - (b.functionListId ?? 0))
    },
    setFunctionListIds (state, functionLists: FunctionList[]) {
      state.functionListsIds = functionLists.sort((a, b) => (a.functionListId ?? 0) - (b.functionListId ?? 0))
    },
    replaceFunctionList (state, functionList: FunctionList) {
      const index = state.functionLists.findIndex((i: FunctionList) => i.functionListId === functionList.functionListId)
      if (index === -1) {
        return
      }
      state.functionLists[index] = functionList
      state.functionLists = [...state.functionLists]
    },
    addFunctionList (state, functionList: FunctionList) {
      state.functionLists.push(functionList)
      state.functionLists = state.functionLists.sort((a: FunctionList, b: FunctionList) => (a.functionListId ?? 0) - (b.functionListId ?? 0))
    },
    deleteFunctionList (state, id: number) {
      state.functionLists = state.functionLists.filter((i: FunctionList) => i.functionListId !== id)
    }
  },
  actions: {
    load ({ commit }) {
      Vue.axios
        .get('macrolib/functionlist')
        .then((result) => {
          commit('setFunctionLists', result.data)
        })
    },
    loadIds ({ commit }) {
      Vue.axios
        .get('macrolib/functionlist/reduce')
        .then((result) => {
          console.log(result.data)
          commit('setFunctionListIds', result.data)
        })
    },
    async updateFunctionList ({ commit }, { id, request }: {id:number, request: any}) {
      const response = await FunctionListService.updateFunctionList({ id, requestBody: request })
      commit('replaceFunctionList', response)
    },
    async getNextFunctionListId ({ commit }) {
      const response = await FunctionListService.getNextSuitableId()
      return response.nextId
    },
    async addNewFunctionList ({ commit }, requestBody: FunctionList) {
      const response = await FunctionListService.addFunctionList({ requestBody })
      commit('addFunctionList', response)
      return response
    },
    async deleteFunctionList ({ commit }, id: number) {
      try {
        await FunctionListService.deleteFunctionListById({ id })
        commit('deleteFunctionList', id)
        return id
      } catch (e) {
        return null
      }
    }
  }
}
export default functionList
