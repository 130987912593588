import { addSetting, changeSetting, deleteSetting, findAllSettings } from '@/api/settings'
import { Setting, SettingPostRequest } from '@/__generated__'
import { Module } from 'vuex'
const settings: Module<any, any> = {
  namespaced: true,
  mutations: {
    setSettings (state, settings: Setting[]) {
      state.settings = settings
    },
    addSetting (state, setting: Setting) {
      state.settings = [...state.settings, setting]
    },
    changeSetting (state, setting: Setting) {
      const index = state.settings.findIndex((i: Setting) => i.id === setting.id)
      state.settings[index] = setting
      state.settings = [...state.settings]
    },
    deleteSetting (state, name: string) {
      state.settings = state.settings.filter((i: Setting) => i.settingName !== name)
    }
  },
  state: {
    settings: [] as Setting[]
  },
  actions: {
    async getSettings ({ commit }) {
      const a = await findAllSettings()
      commit('setSettings', a)
    },
    async addSetting ({ commit }, request: SettingPostRequest) {
      const a = await addSetting(request)
      commit('addSetting', a)
    },
    async changeSetting ({ commit }, { name, value }: { name: string, value: string }) {
      const a = await changeSetting(name, value)
      commit('changeSetting', a)
    },
    async deleteSetting ({ commit }, name: string) {
      await deleteSetting(name)
      commit('deleteSetting', name)
    }
  }
}
export default settings
