
import ConstructionDetailView from '@/components/ConstructionDetailView.vue'
import Vue from 'vue'
import { Bus } from '@/main'
import ConstructionModel from '@/models/ConstructionModel'
export default Vue.extend({
  name: 'PlantTable',
  components: { ConstructionDetailView },
  data: () => ({
    search: '',
    addDialog: false,
    reference: new ConstructionModel(),
    dialog: false,
    lvb: [
      {
        key: 'NEVER',
        label: 'Nie'
      },
      {
        key: 'NONE',
        label: 'Keins'
      },
      {
        key: 'TMU',
        label: 'TMU'
      },
      {
        key: 'BMD',
        label: 'BMA0804 / BMD1204'
      },
      {
        key: 'COUPLING_RELAY',
        label: 'Koppelrelais'
      }
    ],
    headers: [
      {
        text: 'ID (generiert)',
        sortable: false,
        value: 'id'
      },
      { text: 'Aktiv sichtbar', value: 'isActive' },
      { text: 'Name', value: 'name', sortable: true },
      { text: 'Einheit', value: 'unit' },
      { text: 'LVB', value: 'localOverrideOperation' },
      { text: 'Luftseitig', value: 'airSided' },
      { text: 'Ventil', value: 'hasValve' },
      { text: 'Benutzerdefinierte Komponente', value: 'customConstruction' },
      { text: 'Aktionen', value: 'actions' }
    ]
  }),
  computed: {
    constructions () {
      return this.$store.state.constructions
    },
    units () {
      return this.$store.state.units.units
    },
    customConstructionId () {
      return this.$store.state.constructionDetail.customConstructionId
    }
  },
  methods: {
    changeCustomConstructionId (newId: number) {
      this.$store.dispatch('constructionDetail/setCustomConstructionId', newId)
    },
    changeIsActive (itemId: number, value: boolean) {
      this.$store.dispatch('patchConstruction', {
        id: itemId,
        payload: {
          isActive: value
        }
      }).then((i) => {
        Bus.$emit('show-snackbar', `Aktiver Status von ${i.name} wurde aktualisiert`)
      })
    },
    changeHasValve (itemId: number, value: boolean) {
      this.$store.dispatch('patchConstruction', {
        id: itemId,
        payload: {
          hasValve: value
        }
      }).then((i) => {
        Bus.$emit('show-snackbar', `Ventilstatus von ${i.name} wurde aktualisiert`)
      })
    },
    changeAirSided (itemId: number, value: boolean) {
      this.$store.dispatch('constructionDetail/patch', {
        id: itemId,
        payload: {
          airSided: value
        }
      }).then((i) => {
        Bus.$emit('show-snackbar', `Komponente ${i.name} wurde aktualisiert`)
      })
    },
    saveNewConstruction () {
      this.addDialog = false
      this.$store.dispatch('addConstruction', {
        payload: {
          name: this.reference.name,
          unitId: this.reference.unit,
          localOverrideOperation: this.reference.localOverrideOperation
        }
      })
    },
    openDialog (item: any) {
      Bus.$emit('open-construction-detail', item.id)
    },
    updateLVB (item: any) {
      this.$store.dispatch('constructionDetail/patch', {
        id: item.id,
        payload: {
          localOverrideOperation: item.localOverrideOperation
        }
      }).then((i) => {
        Bus.$emit('show-snackbar', `Komponente ${i.name} wurde aktualisiert`)
      })
    }
  }
})
