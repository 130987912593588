
import Vue from 'vue'
import ConnectionDialog from '@/views/ConnectionDialog.vue'
import { Bus } from '@/main'

export default Vue.extend({
  name: 'App',
  components: { ConnectionDialog },
  data: () => ({
    isConnectingToBackend: false,
    drawer: null,
    snackbar: false,
    snackbarText: 'Snackbar',
    group: null,
    items: [
      {
        icon: 'mdi-home',
        route: '/',
        adminsOnly: false,
        title: 'Home'
      },
      {
        icon: 'mdi-database',
        route: '/items',
        adminsOnly: false,
        title: 'Artikel'
      },
      {
        icon: 'mdi-lightbulb-group',
        route: 'constructions',
        adminsOnly: false,
        title: 'Komponenten'
      },
      {
        icon: 'mdi-filmstrip',
        route: 'strips',
        adminsOnly: false,
        title: 'Streifen'
      },
      {
        icon: 'mdi-group',
        route: 'plants',
        adminsOnly: false,
        title: 'Anlagen'
      },
      {
        icon: 'mdi-table',
        route: 'functionlist',
        adminsOnly: false,
        title: 'Funktionslisten'
      },
      {
        icon: 'mdi-earth',
        route: 'countries',
        adminsOnly: false,
        title: 'Länder'
      },
      {
        icon: 'mdi-book',
        route: 'descriptors',
        adminsOnly: false,
        title: 'Wörterbuch'
      },
      {
        icon: 'mdi-account-multiple',
        route: 'roles',
        adminsOnly: false,
        title: 'Rollen'
      },
      {
        icon: 'mdi-account-wrench',
        route: 'services',
        adminsOnly: true,
        title: 'Dienstleistungen'
      },
      {
        icon: 'mdi-cog',
        route: 'settings',
        adminsOnly: true,
        title: 'Anwendungseinstellungen'
      },
      {
        icon: 'mdi-table-question',
        route: 'cleaning',
        adminsOnly: true,
        title: 'Artikelprüfung'
      }
    ]
  }),
  computed: {
    isConnectedToBackend () {
      return this.$store.state.isInNetwork
    },
    filteredItems () {
      return this.items.filter((i) => (!i.adminsOnly || this.$store.state.currentUser.isAdmin))
    }
  },
  created () {
    Bus.$on('show-snackbar', (text: string) => {
      this.snackbarText = text
      this.snackbar = true
    })
    if (!this.$store.state.isInNetwork) {
      this.isConnectingToBackend = true
      this.$store.dispatch('loadDefaultUser')
        .catch(() => {
          this.isConnectingToBackend = false
          Bus.$emit('show-snackbar', 'Konnte nicht zum Backend verbinden.')
        })
        .then(() => {
          this.isConnectingToBackend = false
        })
    }
  },
  methods: {
    pushRouter (route: string) {
      this.$router.push(route)
    }
  }
})
