
import ServicesDatapointComponent from '@/components/ServicesDatapointComponent.vue'
import ServicesPercentageComponent from '@/components/ServicesPercentageComponent.vue'
import CorrectionFactorComponent from '@/components/CorrectionFactorComponent.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    ServicesDatapointComponent, ServicesPercentageComponent, CorrectionFactorComponent
  },
  data: () => ({
    tabs: 0
  })
})
