
import Vue from 'vue'
import PlantTable from '@/components/PlantTable.vue'
export default Vue.extend({
  name: 'PlantView',
  components: { PlantTable },
  data: () => ({
    tabs: null
  })
})
