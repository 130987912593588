/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Setting } from '../models/Setting';
import type { SettingPostRequest } from '../models/SettingPostRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

  /**
   * Alle Einstellungen finden
   * Holt alle Einstellungen, die im System gespeichert sind.
   * @returns Setting OK
   * @throws ApiError
   */
  public static findAllSettings(): CancelablePromise<Array<Setting>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/settings',
    });
  }

  /**
   * Neue Einstellung hinzufügen
   * Fügt eine neue Einstellung hinzu.
   * @returns Setting OK
   * @throws ApiError
   */
  public static addSetting({
    requestBody,
    body,
  }: {
    requestBody: SettingPostRequest,
    /**
     * Die Anforderungsdaten für die neue Einstellung. Enthält Typ, Einstellungsname (ID) und Einstellung.
     */
    body?: any,
  }): CancelablePromise<Setting> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/settings',
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Einstellung löschen
   * Löscht eine spezifische Einstellung basierend auf ihrem Namen.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteSetting({
    name,
  }: {
    /**
     * Der Name der zu löschenden Einstellung.
     */
    name: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/settings/{name}',
      path: {
        'name': name,
      },
    });
  }

  /**
   * Einstellung ändern
   * Ändert eine spezifische Einstellung basierend auf ihrem Namen.
   * @returns Setting OK
   * @throws ApiError
   */
  public static changeSetting({
    name,
    value,
  }: {
    /**
     * Der Name der Einstellung.
     */
    name: string,
    /**
     * Der neue Wert der Einstellung.
     */
    value: string,
  }): CancelablePromise<Setting> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/settings/{name}',
      path: {
        'name': name,
      },
      query: {
        'value': value,
      },
    });
  }

}
