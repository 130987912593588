
import Vue from 'vue'
import { Bus } from '@/main'
import PlantModel from '@/models/PlantModel'
export default Vue.extend({
  created () {
    Bus.$on('tunePlant', (item: PlantModel) => {
      this.editedItem = item
      if (this.editedItem.id === -1) {
        this.formTitle = 'Anlage hinzufügen'
      } else {
        this.formTitle = 'Anlage bearbeiten'
      }
      this.dialog = true
    })
  },
  data: () => ({
    dialog: false,
    editedItem: new PlantModel(),
    formTitle: 'Anlage',
    text: '',
    snackbar: false,
    rules: {
      isNumber: (value: string) => Number.isInteger(parseInt(value)) || 'Muss eine Ganzzahl sein',
      minZero: (value: number) => value >= 0 || 'Darf nicht negativ sein',
      maxVal: (value: number) => value <= 10 || 'Darf maximal 10 sein'
    }
  }),
  methods: {
    close () {
      this.dialog = false
    },
    save () {
      if (this.editedItem.macroId !== null && this.editedItem.macroId.trim() === '') {
        this.editedItem.macroId = null as any
      }
      if (this.editedItem.id === -1) {
        this.$store.dispatch('addPlant', this.editedItem).then(() => {
          this.dialog = false
          Bus.$emit('show-snackbar', 'Anlage wurde erstellt')
        }, (error) => {
          Bus.$emit('show-snackbar', 'Anlage konnte nicht erstellt werden')

          this.dialog = false
          console.log(error)
        })
      } else {
        // Edit Entity
        this.$store.dispatch('editPlant', this.editedItem).then(() => {
          this.dialog = false
          Bus.$emit('show-snackbar', 'Anlage wurde aktualisiert')
        })
      }
    }
  }
})
