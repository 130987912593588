import Store from '@/store'
import RelationInterface from '@/models/RelationInterface'
import DescriptionModel from '@/models/DescriptionModel'
import RelationFactory from '@/controllers/RelationFactory'
import ConnectionRequest from '@/models/ConnectionRequest'

export default abstract class IRelationController<T extends RelationInterface, E> {
  title = 'Entitäten';
  abstract factory: RelationFactory<T>
  abstract headers: Array<any>
  relations: Array<T> = [];
  // Modified is a Copy of Relations and should be kept as one
  modified: Array<T> = [];
  abstract names (): Array<E>;
  abstract prefetchDataReference: string
  abstract loadFromStoreReference: string
  abstract deleteItemFromStoreReference: string
  abstract addItemToStoreReference: string
  parentId = -1
  abstract modifyItemInStoreReference: string

  abstract toConnectionRequest(item: T): ConnectionRequest

  load (parentId: number): void {
    this.parentId = parentId
    Store.dispatch(this.loadFromStoreReference, parentId).then((items: [T]) => {
      this.relations = items
      this.modified = JSON.parse(JSON.stringify(items))
    })
  }

  descriptions (): Array<DescriptionModel> {
    return Store.state.descriptions
  }

  init () : void {
    Store.dispatch(this.prefetchDataReference)
    Store.dispatch('loadDescriptions')
  }

  // Revert all Items
  revert (): void {
    this.modified = JSON.parse(JSON.stringify(this.relations))
  }

  commitChanges () : number {
    let num = 0
    this.modified.forEach((item: T) => {
      if (item.id === -1) {
        // Add Request
        num++
        Store.dispatch(this.addItemToStoreReference, { id: this.parentId, entity: this.toConnectionRequest(item) })
          .then((res: T) => {
            Object.assign(item, JSON.parse(JSON.stringify(res)))
          })
      } else {
        // Modify Request
        if (!this.equalsStoreItem(item)) {
          num++
          console.log('Modify', this.toConnectionRequest(item))
          Store.dispatch(this.modifyItemInStoreReference, { id: this.parentId, entity: this.toConnectionRequest(item) })
            .then((res: T) => {
              Object.assign(item, JSON.parse(JSON.stringify(res)))
            })
        }
      }
    })
    return num
  }

  revertItem (item: T): void {
    try {
      const i = this.findOriginal(item)
      Object.assign(item, JSON.parse(JSON.stringify(i)))
    } catch (e) {
      // Remove
      const index = this.modified.indexOf(item)
      this.modified.splice(index, 1)
    }
  }

  newItem () : void {
    this.modified.push(this.factory.create())
  }

  delete (item: T): Promise<T> {
    return new Promise<T>(resolve => {
      Store.dispatch(this.deleteItemFromStoreReference, { id: this.parentId, entity: item }).then(() => {
        const i = this.modified.findIndex((i) => {
          return i.id === item.id
        })
        this.modified.splice(i, 1)
        resolve(item)
      })
    })
  }

  equalsStore (): boolean {
    return JSON.stringify(this.relations) === JSON.stringify(this.modified)
  }

  findOriginal (item: T): T {
    const i = this.relations.find((i: T) => {
      return i.id === item.id
    })
    if (i === undefined) {
      throw new Error('Not Found')
    }
    return i
  }

  equalsStoreItem (item: T): boolean {
    try {
      const f = this.findOriginal(item)
      return JSON.stringify(f) === JSON.stringify(item)
    } catch (e) {
      return false
    }
  }
}
