import Vue from 'vue'
import axios from 'axios'
import { Module } from 'vuex'
import ConstructionModel from '@/models/ConstructionModel'
import { getCustomConstructionId, setCustomConstructionId } from '@/api/construction'

const constructionDetail: Module<any, any> = {
  namespaced: true,
  state: () => ({
    construction: new ConstructionModel(),
    customConstructionId: undefined as number | undefined
  }),
  mutations: {
    setConstruction (state, construction: ConstructionModel) {
      state.construction = construction
    },
    setCustomConstructionId (state, constructionId: number | undefined) {
      state.customConstructionId = constructionId
    }
  },
  actions: {
    loadConstruction ({ commit }, id) {
      return new Promise<void>(resolve => {
        Vue.axios
          .get(`macrolib/constructions/${id}`)
          .then(result => {
            commit('setConstruction', result.data)
            resolve()
          })
      })
    },
    async loadCustomConstructionId ({ commit }) {
      const id = await (await getCustomConstructionId()).customConstructionId
      commit('setCustomConstructionId', id)
    },
    async setCustomConstructionId ({ commit }, newId: number) {
      const id = await (await setCustomConstructionId({ constructionId: newId })).customConstructionId
      commit('setCustomConstructionId', id)
    },
    update ({ commit, state }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .get(`macrolib/constructions/${state.construction.id}`)
          .then(result => {
            commit('setConstruction', result.data)
            resolve()
          })
      })
    },
    addItemToSpecification (t, { specId, itemId, quantity }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .put(`macrolib/specifications/${specId}/items?itemId=${itemId}${quantity ? `&quantity=${quantity}` : ''}`)
          .then(result => {
            resolve()
          })
      })
    },
    removeItemFromSpecification (t, { specId, itemId }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .delete(`macrolib/specifications/${specId}/items?itemId=${itemId}`)
          .then(result => {
            resolve()
          })
      })
    },
    updateAttribute ({ commit, dispatch }, { id, data }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .put(`macrolib/constructions/${id}`, data)
          .then(result => {
            commit('setConstruction', result.data)
            // Reload list
            resolve()
          })
      })
    },
    patch ({ commit }, { id, payload }) {
      return new Promise<ConstructionModel>(resolve => {
        Vue.axios
          .patch(`macrolib/constructions/${id}`, payload)
          .then(result => {
            commit('setConstruction', result.data)
            // Reload list
            resolve(result.data)
          })
      })
    }
  }
}
export default constructionDetail
