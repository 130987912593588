import IRelationController from '@/controllers/IRelationController'
import PlantConstructionRelationModel from '@/models/PlantConstructionRelationModel'
import Store from '@/store/index.ts'
import RelationFactory from '@/controllers/RelationFactory'
import ConstructionModel from '@/models/ConstructionModel'
import PlantStripRelationModel from '@/models/PlantStripRelationModel'
import ConnectionRequest from '@/models/ConnectionRequest'
import StripModel from '@/models/StripModel'

class PlantStripFactory implements RelationFactory<PlantStripRelationModel> {
  create (): PlantStripRelationModel {
    return new PlantStripRelationModel()
  }
}

export default class PlantStripRelationController extends IRelationController<PlantStripRelationModel, StripModel> {
  title = 'Streifen'
  addItemToStoreReference = 'addPlantStrip'
  deleteItemFromStoreReference = 'deletePlantStrip'
  loadFromStoreReference = 'loadPlantStrips'
  modifyItemInStoreReference = 'changePlantStrip'
  headers = [
    { text: 'Sortierung', value: 'orderBy', sortable: true },
    { text: 'ID (generiert)', value: 'id', width: '5%', sortable: true },
    { text: 'Name', width: '40%', value: 'strip', sortable: false },
    { text: 'Bezeichner', width: '40%', value: 'description', sortable: false },
    { text: 'Aktionen', value: 'actions', sortable: false }
  ]

  prefetchDataReference = 'loadStrips'

  names (): Array<StripModel> {
    return Store.state.strips
  }

  factory = new PlantStripFactory()

  toConnectionRequest (item: PlantStripRelationModel): ConnectionRequest {
    return new ConnectionRequest(
      item.strip.id,
      item.description.id,
      item.orderBy,
      item.id
    )
  }
}
