
import Vue from 'vue'
import { Bus } from '@/main'
import ItemTable from '@/components/ItemTable.vue'
import { ApiError, ItemDTO } from '@/__generated__'
export default Vue.extend({
  name: 'ItemView',
  components: {
    ItemTable
  },
  data: () => ({
    tabs: 1,
    name: '',
    description: '',
    addDialog: false,
    text: ['Feldgerät', 'Kabel', 'Schaltschrank', 'DDC', 'Dienstleistung', 'GLT', 'Nicht zugeordneten Artikel'],
    key: ['FIELD_DEVICE', 'CABLE', 'SWITCH_CABINET', 'DDC', 'SERVICE', 'GLT', 'NOT_DEFINED']
  }),
  computed: {
    fieldDevices () {
      return this.$store.state.items.fielddevices
    },
    cabinets () {
      return this.$store.state.items.cabinets
    },
    cables () {
      return this.$store.state.items.cables
    },
    ddc () {
      return this.$store.state.items.ddcs
    },
    service () {
      return this.$store.state.items.services
    },
    notDefined () {
      return this.$store.state.items.notDefined
    },
    glt () {
      return this.$store.state.items.glt
    }
  },
  methods: {
    removeItem (item: ItemDTO) {
      this.$store.dispatch('items/deleteItem', { data: item, type: this.key[this.tabs] }).then(() => {
        Bus.$emit('show-snackbar', `"${item.name}" wurde gelöscht`)
      }).catch(() => {
        Bus.$emit('show-snackbar', `"${item.name}" konnte nicht gelöscht werden. Bestehen vielleicht noch Referenzen?`)
      })
    },
    async addItem () {
      const payload = {
        name: this.name,
        type: this.key[this.tabs],
        description: this.description === null || this.description.length === 0 ? null : this.description
      }
      try {
        const r = await this.$store.dispatch('items/addItem', payload)
        Bus.$emit('show-snackbar', `${this.text[this.tabs]} ${r.name} wurde erstellt`)
        this.addDialog = false
      } catch (e) {
        if (e instanceof ApiError) {
          switch (e.status) {
            case 404:
              Bus.$emit('show-snackbar', `"${this.name}" konnte nicht hinzugefügt werden, da er nicht in der NAV-Datenbank existiert`)
              break
            case 409:
              Bus.$emit('show-snackbar', `"${this.name}" konnte nicht hinzugefügt werden, da er bereits existiert`)
              break
            default:
              Bus.$emit('show-snackbar', '"Ein unerwarteter Fehler ist aufgetreten')
              break
          }
        }
      }
    }
  }
})
