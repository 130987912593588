<template>
  <v-layout class="pb-10" column>
    <v-data-table sort-by="createdAt" sort-desc :items="descriptions" :loading="loading" :headers="headers">
      <template v-slot:no-data>
        Im Moment gibt es keine Relationsbeschreibungen
      </template>
      <template v-slot:loading>
        Relationsbeschreibungen werden geladen...
      </template>
      <template v-slot:item.createdAt="{ value }">
        {{ new Date(value).toLocaleString('de-DE') }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="edit(item)" icon><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn :disabled="item.numUsages > 0" @click="del(item.id)" icon><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-btn absolute fab left style="bottom: 20px" bottom @click="add"><v-icon>mdi-plus</v-icon></v-btn>
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title>Relation {{ addModel.id === -1 ? 'hinzufügen' : 'bearbeiten' }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="addModel.description" label="Beschreibung"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="addModel.id === -1" text @click="save">Hinzufügen</v-btn>
          <v-btn v-else text @click="save">Aktualisieren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import DescriptionModel from '@/models/DescriptionModel'
import { Bus } from '@/main'

export default {
  name: 'DictionaryView',
  data: () => ({
    tab: 0,
    dialog: false,
    addModel: new DescriptionModel(),
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Beschreibung', value: 'description' },
      { text: 'Anzahl der Benutzungen', value: 'numUsages' },
      { text: 'Erstelldatum', value: 'createdAt' },
      { text: 'Aktionen', value: 'actions' }
    ]
  }),
  methods: {
    save () {
      if (this.addModel.id === -1) {
        this.$store.dispatch('dictionary/addDescription', { description: this.addModel.description }).then((i) => {
          Bus.$emit('show-snackbar', `Beschreibung ${i.description} wurde hinzugefügt`)
          this.dialog = false
        }).catch((e) => {
          Bus.$emit('show-snackbar', `Beschreibung ${this.addModel.description} konnte nicht hinzugefügt werden, existiert sie schon?`)
          this.dialog = false
        })
      } else {
        this.$store.dispatch('dictionary/updateDescription', { id: this.addModel.id, body: { description: this.addModel.description } }).then((i) => {
          Bus.$emit('show-snackbar', `Beschreibung von ${this.addModel.description} zu ${i.description} geändert`)
          this.dialog = false
        }).catch((e) => {
          Bus.$emit('show-snackbar', `Beschreibung ${this.addModel.description} konnte nicht aktualisiert werden, existiert sie schon?`)
          this.dialog = false
        })
      }
    },
    del (id) {
      this.$store.dispatch('dictionary/deleteDescription', id)
    },
    edit (item) {
      this.addModel.id = item.id
      this.addModel.description = item.description
      this.dialog = true
    },
    add () {
      this.addModel = new DescriptionModel()
      this.dialog = true
    }
  },
  computed: {
    loading () {
      return this.$store.state.dictionary.loading
    },
    descriptions () {
      return this.$store.state.dictionary.relationDescriptions
    }
  }
}
</script>

<style scoped>

</style>
