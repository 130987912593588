import { getAllDatapointServiceCalculations, addDatapointServiceCalculation, deleteDatapointServiceCalculation, overwriteDatapointConfiguration, getRoleItemPercentages, addRoleItemPercentage, deleteRoleItemPercentage, getAllCorrectionFactors, addCorrectionFactor, changeCorrectionFactor, changeRoleItemPercentage } from '@/api'
import { CorrectionFactor, CorrectionFactorPostRequest, CorrectionFactorPutRequest, RoleItemPercentageDTO, RoleItemPercentagePostRequest, RoleItemPercentagePutRequest, ServiceDataPostRequest, ServiceDataPutRequest, ServiceEntryDTO } from '@/__generated__'
import { Module } from 'vuex'
const services: Module<any, any> = {
  namespaced: true,
  mutations: {
    setServiceData (state, data: ServiceEntryDTO[]) {
      state.serviceData = data
    },
    addServiceEntry (state, data: ServiceEntryDTO) {
      state.serviceData = [...state.serviceData, data]
    },
    deleteServiceEntryById (state, id: number) {
      state.serviceData = state.serviceData.filter((i: ServiceEntryDTO) => i.id !== id)
    },
    updateServiceEntry (state, data: ServiceEntryDTO) {
      state.serviceData = [data, ...state.serviceData.filter((i: ServiceEntryDTO) => i.id !== data.id)]
    },
    setPercentages (state, data: RoleItemPercentageDTO[]) {
      state.percentages = data
    },
    addPercentage (state, data: RoleItemPercentageDTO) {
      state.percentages = [...state.percentages, data]
    },
    deletePercentage (state, id: number) {
      state.percentages = state.percentages.filter((i: RoleItemPercentageDTO) => i.id !== id)
    },
    changePercentage (state, data: RoleItemPercentageDTO) {
      const i = state.percentages.findIndex((i: RoleItemPercentageDTO) => i.id === data.id)
      state.percentages[i] = data
      state.percentages = [...state.percentages]
    },
    setCorrectionFactors (state, factors: CorrectionFactor[]) {
      state.correctionFactors = factors
    },
    addCorrectionFactor (state, factor: CorrectionFactor) {
      state.correctionFactors = [factor, ...state.correctionFactors]
    },
    changeCorrectionFactor (state, factor: CorrectionFactor) {
      const index = state.correctionFactors.findIndex((i: CorrectionFactor) => i.id === factor.id && i.shared === factor.shared)
      state.correctionFactors[index] = factor
      state.correctionFactors = [...state.correctionFactors]
    }
  },
  state: {
    serviceData: [] as ServiceEntryDTO[],
    percentages: [] as RoleItemPercentageDTO[],
    correctionFactors: [] as CorrectionFactor[]
  },
  actions: {
    async getConfiguration ({ commit }) {
      const a = await getAllDatapointServiceCalculations()
      commit('setServiceData', a)
      console.log(a)
    },
    async addService ({ commit }, data: ServiceDataPostRequest) {
      const a = await addDatapointServiceCalculation(data)
      commit('addServiceEntry', a)
    },
    async updateService ({ commit }, { id, data }: { id: number, data: ServiceDataPutRequest }) {
      const a = await overwriteDatapointConfiguration(data, id)
      commit('updateServiceEntry', a)
    },
    async deleteService ({ commit }, id: number) {
      await deleteDatapointServiceCalculation(id)
      commit('deleteServiceEntryById', id)
    },
    async getPercentages ({ commit }) {
      const a = await getRoleItemPercentages()
      commit('setPercentages', a)
    },
    async addPercentage ({ commit }, { request }: { request: RoleItemPercentagePostRequest }) {
      const a = await addRoleItemPercentage(request)
      commit('addPercentage', a)
    },
    async changePercentage ({ commit }, { id, request } : { id: number, request: RoleItemPercentagePutRequest }) {
      const a = await changeRoleItemPercentage(id, request)
      commit('changePercentage', a)
    },
    async deletePercentage ({ commit }, id: number) {
      await deleteRoleItemPercentage(id)
      commit('deletePercentage', id)
    },
    async loadCorrectionFactors ({ commit }) {
      const a = await getAllCorrectionFactors()
      commit('setCorrectionFactors', a)
    },
    async addCorrectionFactor ({ commit }, request: CorrectionFactorPostRequest) {
      const a = await addCorrectionFactor(request)
      commit('addCorrectionFactor', a)
    },
    async changeCorrectionFactor ({ commit }, { id, shared, request }: { id: CorrectionFactor.id, shared: boolean, request: CorrectionFactorPutRequest }) {
      const a = await changeCorrectionFactor(id, shared, request)
      commit('changeCorrectionFactor', a)
    }
  }
}
export default services
