export default class ConnectionRequest {
  childId = -1;
  descriptionId = -1;
  orderBy = 0;
  id = -1;
  constructor (childId: number, descriptionId: number, orderBy: number, relationId: number) {
    this.childId = childId
    this.descriptionId = descriptionId
    this.orderBy = orderBy
    this.id = relationId
  }
}
