
import Vue from 'vue'

export default Vue.extend({
  name: 'Home',
  computed: {
    loading () {
      return this.$store.state.metadata.strips === -1
    },
    firstName () {
      return this.$store.state.currentUser.firstName
    },
    isAdmin () {
      return this.$store.state.currentUser.isAdmin
    },
    metas () {
      return [
        {
          title: 'Komponenten',
          color: 'teal',
          url: 'constructions',
          icon: 'mdi-lightbulb-group',
          quantity: this.$store.state.metadata.constructions
        },
        {
          title: 'Streifen',
          color: 'cyan',
          url: 'strips',
          icon: 'mdi-filmstrip',
          quantity: this.$store.state.metadata.strips
        },
        {
          title: 'Anlagen',
          url: 'plants',
          color: 'light-blue',
          icon: 'mdi-group',
          quantity: this.$store.state.metadata.plants
        },
        {
          title: 'Feldgeräte',
          url: 'items',
          color: 'red',
          icon: 'mdi-thermometer',
          quantity: this.$store.state.metadata.fielddevices
        },
        {
          title: 'Schaltschränke',
          url: 'items',
          color: 'pink',
          icon: 'mdi-server',
          quantity: this.$store.state.metadata.cabinets
        },
        {
          title: 'Kabel',
          url: 'items',
          color: 'purple',
          icon: 'mdi-cable-data',
          quantity: this.$store.state.metadata.cables
        }
      ]
    }
  },
  methods: {
    navigateTo (url: string) {
      this.$router.push(url)
    }
  }
})
