<template>
  <v-layout column style="height: 90vh">
    <v-flex class="flex-grow-0">
      <v-text-field
        rounded
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('actions.searchCountries')"
        single-line
        hide-details
        class="ma-4"
      ></v-text-field>
      <v-divider></v-divider>
    </v-flex>
    <v-flex class="flex-grow-1" md6 style="overflow-y: scroll">
      <v-data-table disable-pagination :search="search" :headers="headers" :items="countries">
        <template v-slot:[`item.flag`]="{ item }">
          <country-flag :country="item.short"></country-flag>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'

export default Vue.extend({
  name: 'CountryTable.vue',
  components: {
    CountryFlag
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'ID (generiert)',
        sortable: false,
        value: 'id'
      },
      { text: 'Name', value: 'name', sortable: true },
      { text: 'ISO-Kürzel', value: 'short' },
      { text: 'Flagge', value: 'flag' }
    ]
  }),
  computed: {
    countries () {
      return this.$store.state.countries
    }
  }
})
</script>

<style scoped>

</style>
