/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CorrectionFactor } from '../models/CorrectionFactor';
import type { CorrectionFactorPostRequest } from '../models/CorrectionFactorPostRequest';
import type { CorrectionFactorPutRequest } from '../models/CorrectionFactorPutRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CorrectionFactorsService {

  /**
   * Ändere eine Datenpunktgewichtung
   * Ändert die Parameter eine Datenpunktgewichtung
   * @returns CorrectionFactor OK
   * @throws ApiError
   */
  public static changeCorrectionFactor({
    id,
    shared,
    requestBody,
  }: {
    id: 'BINARY_OUTPUT' | 'ANALOG_OUTPUT' | 'BINARY_INPUT' | 'ANALOG_INPUT' | 'COUNTER_INPUT',
    shared: boolean,
    requestBody: CorrectionFactorPutRequest,
  }): CancelablePromise<CorrectionFactor> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/services/correction-factors/{id}',
      path: {
        'id': id,
      },
      query: {
        'shared': shared,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Rufe alle verfügbaren Datenpunktgewichtungen ab
   * Ruft alle Datenpunktgewichtungen, die für die DNL-Auslegung genutzt werden ab. Beinhaltet Datenpunkttyp (AA, AE, BA, BE), Faktor (Dezimal), Summand (Dezimalzahl), und ob er geteilt ist
   * @returns CorrectionFactor OK
   * @throws ApiError
   */
  public static getAllCorrectionFactors(): CancelablePromise<Array<CorrectionFactor>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/correction-factors',
    });
  }

  /**
   * Füge neue Datenpunktgewichtung hinzu
   * Fügt eine neue Datenpunktgewichtung für eine Kombination von Datenpunkttyp und ob dieser geteilt ist, hinzu. Wirft einen Fehler, falls die Konfiguration in dieser Kombination schon existiert. Von Admins genutzt
   * @returns CorrectionFactor OK
   * @throws ApiError
   */
  public static addCorrectionFactor({
    requestBody,
  }: {
    requestBody: CorrectionFactorPostRequest,
  }): CancelablePromise<CorrectionFactor> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/services/correction-factors',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
