<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
    @keydown.right="nextItem"
    @keydown.left="previousItem">
    <v-card height="80vh">
      <v-card-title>
        <v-btn icon @click="previousItem"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-toolbar-title>{{item.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="nextItem"><v-icon>mdi-arrow-right</v-icon></v-btn>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tabs">
          <v-tab>{{ $t('dialog.components') }}</v-tab>
          <v-tab>{{ $t('strip.strips') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-col cols="12">
              <relation-table :relation-controller="plantConstructionController"/>
            </v-col>
          </v-tab-item>
          <v-tab-item>
            <v-col cols="12">
              <relation-table :relation-controller="plantStripController"/>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue'
import { Bus } from '@/main'
import PlantModel from '@/models/PlantModel'
import PlantConstructionRelationModel from '@/models/PlantConstructionRelationModel'
import RelationTable from '@/components/RelationTable.vue'
import PlantConstructionRelationController from '@/controllers/PlantConstructionRelationController'
import PlantStripRelationController from '@/controllers/PlantStripRelationController'
export default Vue.extend({
  name: 'ConnectionDialog.vue',
  components: { RelationTable },
  created () {
    this.$store.dispatch('loadConstructions')
    this.$store.dispatch('loadDescriptions')
    Bus.$on('next-step', () => {
      this.nextItem()
    })
    Bus.$on('previous-step', () => {
      this.previousItem()
    })
    Bus.$on('close-dialog', () => {
      this.closeDialog()
    })
  },
  mounted () {
    Bus.$on('open-plant-dialog', (item) => {
      this.setItem(item)
      this.dialog = true
    })
  },
  data: () => ({
    plantConstructionController: new PlantConstructionRelationController(),
    plantStripController: new PlantStripRelationController(),
    innerDialog: false,
    deleteDialog: false,
    dialog: false,
    rules: {
      numberRule: (value) => Number.isInteger(value) || 'Zahl'
    },
    deleteReference: new PlantConstructionRelationModel(),
    tabs: null,
    modifiedConstructions: [],
    callback: '',
    item: new PlantModel(),
    customConstructions: [],
    stripHeaders: [
      { text: 'Relations-ID (statisch)', value: 'relationId', width: '10%' },
      { text: 'Name', width: '40%', value: 'stripName', sortable: true },
      { text: 'Bezeichner', width: '40%', value: 'description', sortable: true }
    ]
  }),
  computed: {
    availableConstructions () {
      return this.$store.state.constructions
    },
    availableDescriptions () {
      return this.$store.state.descriptions
    },
    constructions () {
      return this.modifiedConstructions
    },
    strips () {
      return this.$store.state.plantStrips
    },
    modifications () {
      if (this.customConstructions.length > 0) {
        return true
      }
      return JSON.stringify(this.modifiedConstructions) !== JSON.stringify(this.$store.state.plantConstructions)
    }
  },
  methods: {
    nextItem () {
      const nextIndex = this.$store.state.plants.findIndex((item) => {
        return item.id === this.item.id
      })
      if (nextIndex + 1 >= this.$store.state.plants.length) { return }
      this.setItem(this.$store.state.plants[nextIndex + 1])
    },
    previousItem () {
      const ind = this.$store.state.plants.findIndex((item) => {
        return item.id === this.item.id
      })
      if (ind <= 0) { return }
      this.setItem(this.$store.state.plants[ind - 1])
    },
    setItem (item) {
      this.item = item
      // Trigger the Bus
      this.plantStripController.load(item.id)
      this.plantConstructionController.load(item.id)
    }
  }
})
</script>

<style scoped>

</style>
