
import { ItemUsageElementDTO } from '@/__generated__'
import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    lastAnalysisString: undefined as string | undefined,
    isLoadingAnalysis: false,
    isExporting: false,
    typeMapping: [
      {
        value: 'DDC',
        text: 'DDCs & Module'
      },
      {
        value: 'SWITCH_CABINET',
        text: 'Schaltschrank-Komponenten'
      },
      {
        value: 'NOT_DEFINED',
        text: 'Nicht zugewiesen'
      },
      {
        value: 'FIELD_DEVICE',
        text: 'Feldgerät'
      },
      {
        value: 'SERVICE',
        text: 'Dienstleistungen'
      }
    ],
    headers: [
      { text: '#', value: 'itemId' },
      { text: 'Name', value: 'itemName' },
      { text: 'Artikeltyp', value: 'itemType' },
      { text: 'Komponenten', value: 'usages' }
    ]
  }),
  computed: {
    items (): Array<ItemUsageElementDTO> {
      return this.$store.state.items.itemAvailability?.missingItems ?? []
    },
    itemCount () {
      return this.$store.state.items.itemAvailability?.missingItemQuantity ?? 0
    },
    dataIsUndefined () {
      return this.$store.state.items.itemAvailability === undefined
    }
  },
  methods: {
    exportAsCsv () {
      const csvData = this.convertToCsv(this.items)
      const csvBlob = new Blob([csvData], { type: 'text/csv' })
      const encodedUri = window.URL.createObjectURL(csvBlob)

      // Set the desired file name (e.g., "my_output.csv")
      const fileName = 'output.csv'

      // Append the file name to the URL
      const downloadLink = document.createElement('a')
      downloadLink.href = encodedUri
      downloadLink.download = fileName
      document.body.appendChild(downloadLink)

      // Programmatically click the link to trigger the download
      downloadLink.click()

      // Clean up by removing the link from the DOM
      document.body.removeChild(downloadLink)
    },
    convertToCsv (data: ItemUsageElementDTO[]): string {
      const headers = this.headers.map((e) => e.text).join('; ')

      const rows = data.map((item) => {
        // Convert the enum value to a string
        const itemTypeString = this.typeMapping.find((e) => e.value === item.itemType.toString())?.text ?? item.itemType
        return [`"${item.itemId}"`, `"${item.itemName}"`, `"${itemTypeString}"`, `"${item.usages.join(', ')}"`].join('; ')
      })

      return headers + '\n' + rows.join('\n')
    },
    async loadMissingItems () {
      this.isLoadingAnalysis = true
      await this.$store.dispatch('items/loadItemAvailability')
      this.isLoadingAnalysis = false
      this.lastAnalysisString = this.formatDateInGermanTime(new Date())
    },
    formatDateInGermanTime (date: Date): string {
      const formatter = new Intl.DateTimeFormat('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Europe/Berlin'
      })

      return formatter.format(date)
    }

  }
})
