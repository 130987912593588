
import Vue from 'vue'
import { Bus } from '@/main'
import ItemModel from '@/models/ItemModel'
import { Item, ItemQuantityType } from '@/__generated__'

export default Vue.extend({
  name: 'ItemTable',
  props: {
    items: {
      required: true
    },
    type: {
      type: String,
      required: true
    },
    autoLayout: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    changeItemTypeDialog: {
      value: false,
      itemId: -1,
      type: 'NOT_DEFINED'
    },
    headers: [
      { text: 'Ordnungszahl', value: 'orderBy', sortable: true },
      { text: 'ID', value: 'id', sortable: true },
      { text: 'Typ', value: 'type', sortable: false },
      { text: 'Name', width: '40%', value: 'name', sortable: false },
      { text: 'Beschreibung', width: '40%', value: 'description', sortable: false },
      { text: 'Aktionen', width: '10%', value: 'actions', sortable: false }
    ],
    autoLayoutHeaders: [
      { text: 'Ordnungszahl', value: 'orderBy', sortable: true },
      { text: 'ID', value: 'id', sortable: true },
      { text: 'Typ', value: 'type', sortable: false },
      { text: 'Name', width: '40%', value: 'name', sortable: false },
      { text: 'Beschreibung', width: '40%', value: 'description', sortable: false },
      { text: 'Für Auto Auslegung', value: 'selectedForAutoLayout', sortable: false },
      { text: 'Aktionen', width: '10%', value: 'actions', sortable: false }
    ]
  }),
  computed: {
    availableTypes () {
      if (ItemQuantityType.type !== undefined) {
        return Object.values(ItemQuantityType.type)
      }
      return []
    },
    currentTypeItem () {
      return (this.items as Item[]).find((e) => e.id === this.changeItemTypeDialog.itemId)
    }
  },
  methods: {
    getTranslationForType (type: Item.type) {
      switch (type) {
        case Item.type.FIELD_DEVICE:
          return this.$t('items.fieldDevices')
        case Item.type.SWITCH_CABINET:
          return this.$t('items.switchCabinets')
        case Item.type.CABLE:
          return this.$t('items.cable')
        case Item.type.DDC:
          return this.$t('items.ddc')
        case Item.type.GLT:
          return this.$t('items.glt')
        case Item.type.SERVICE:
          return this.$t('items.services')
        case Item.type.NOT_DEFINED:
          return this.$t('items.notDefined')
      }
    },
    openChangeTypeDialog (id: number) {
      this.changeItemTypeDialog.value = true
      this.changeItemTypeDialog.itemId = id
      // Cache current item type for v-model
      this.changeItemTypeDialog.type = this.currentTypeItem?.type ?? 'NOT_DEFINED'
    },
    removeItem (item: ItemModel) {
      this.$store.dispatch('items/deleteItem', { data: item, type: this.type }).then(() => {
        Bus.$emit('show-snackbar', `"${item.name}" wurde gelöscht`)
      }).catch(() => {
        Bus.$emit('show-snackbar', `"${item.name}" konnte nicht gelöscht werden. Bestehen vielleicht noch Referenzen?`)
      })
    },
    async updateDescription (item: ItemModel, description: string) {
      var i = await this.$store.dispatch('items/updateItem', { id: item.id, body: { description: description }, type: this.type })
      Bus.$emit('show-snackbar', `Beschreibung für ${item.name} ist jetzt ${i.description}`)
    },
    async updateSelectedForAutoLayout (item: ItemModel, selected: boolean) {
      var i = await this.$store.dispatch('items/updateItem', { id: item.id, body: { selectedForAutoLayout: selected }, type: this.type })
      Bus.$emit('show-snackbar', `Auto Layout für ${item.name} ist jetzt ${i.selectedForAutoLayout ? 'an' : 'aus'}`)
    },
    async updateType (item: Item, type: string) {
      if (item.type === type) {
        return
      }
      var i = await this.$store.dispatch('items/updateItemType', { id: item.id, newItemType: type, type: this.type })
      Bus.$emit('show-snackbar', `Type für ${item.name} ist jetzt ${i.type}`)
    },
    async updateOrderBy (item: ItemModel, orderBy: string) {
      var cleanedInput = null
      if (isNaN(parseInt(orderBy))) {
        cleanedInput = null
      } else {
        cleanedInput = parseInt(orderBy)
      }
      var i = await this.$store.dispatch('items/updateItem', { id: item.id, body: { orderBy: cleanedInput }, type: this.type })
      Bus.$emit('show-snackbar', `Ordnungszahl für ${item.name} ist jetzt ${i.orderBy === null ? 'gelöscht' : i.orderBy}`)
    }
  }
})
