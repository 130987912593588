
import items from '@/store/items'
import { ItemDTO, ProjectRole, ServiceDataPostRequest, ServiceDataPutRequest, ServiceEntryDTO } from '@/__generated__'
import Vue from 'vue'
import { VDataTable, VBtn, VIcon, VSpacer, VAutocomplete, VCard, VCardActions, VCardText, VCardTitle, VCheckbox, VDialog, VSelect, VTextField } from 'vuetify/lib'
export default Vue.extend({
  data: () => ({
    addDialog: {
      replaceId: -1 as number | undefined,
      dialog: false,
      itemId: -1,
      pointType: ServiceDataPostRequest.type.ANALOG_INPUT,
      shared: false,
      role: 'MANAGER',
      seconds: 0
    },
    deleteDialog: {
      value: false,
      id: -1
    },
    headers: [
      {
        text: 'Item',
        value: 'item'
      },
      {
        text: 'Arbeitskraft',
        value: 'role'
      },
      {
        text: 'Typ',
        value: 'type'
      },
      {
        text: 'Gemeinsam',
        value: 'shared'
      },
      {
        text: 'Sekunden / Datenpunkt',
        value: 'seconds'
      },
      {
        text: 'Aktionen',
        value: 'actions'
      }
    ],
    pointTypeToTextMapping: [
      {
        text: 'Analoger Eingang',
        value: ServiceEntryDTO.pointType.ANALOG_INPUT
      },
      {
        text: 'Analoger Ausgang',
        value: ServiceEntryDTO.pointType.ANALOG_OUTPUT
      },
      {
        text: 'Binärer Eingang melden',
        value: ServiceEntryDTO.pointType.BINARY_INPUT
      },
      {
        text: 'Binärer Ausgang',
        value: ServiceEntryDTO.pointType.BINARY_OUTPUT
      },
      {
        text: 'Binärer Eingang zählen',
        value: ServiceEntryDTO.pointType.COUNTER_INPUT
      }
    ],
    physicalTypeToText: [
      {
        text: 'Analoger Eingang (physikalisch)',
        value: ServiceEntryDTO.pointType.ANALOG_INPUT
      },
      {
        text: 'Analoger Ausgang (physikalisch)',
        value: ServiceEntryDTO.pointType.ANALOG_OUTPUT
      },
      {
        text: 'Binärer Eingang melden (physikalisch)',
        value: ServiceEntryDTO.pointType.BINARY_INPUT
      },
      {
        text: 'Binärer Ausgang (physikalisch)',
        value: ServiceEntryDTO.pointType.BINARY_OUTPUT
      },
      {
        text: 'Binärer Eingang zählen (physikalisch)',
        value: ServiceEntryDTO.pointType.COUNTER_INPUT
      }
    ],
    sharedTypeToText: [
      {
        text: 'Analoger Eingang (kommunikativ)',
        value: ServiceEntryDTO.pointType.ANALOG_INPUT
      },
      {
        text: 'Analoger Ausgang (kommunikativ)',
        value: ServiceEntryDTO.pointType.ANALOG_OUTPUT
      },
      {
        text: 'Binärer Eingang melden (kommunikativ)',
        value: ServiceEntryDTO.pointType.BINARY_INPUT
      },
      {
        text: 'Binärer Ausgang (kommunikativ)',
        value: ServiceEntryDTO.pointType.BINARY_OUTPUT
      },
      {
        text: 'Binärer Eingang zählen (kommunikativ)',
        value: ServiceEntryDTO.pointType.COUNTER_INPUT
      }
    ]
  }),
  computed: {
    items () {
      return this.$store.state.services.serviceData.map((e: ServiceEntryDTO) => {
        return {
          id: e.id,
          item: e.item?.name,
          role: e.role,
          seconds: e.seconds,
          category: e.item?.name,
          type: this.pointTypeToTextMapping.find(f => f.value === e.pointType)?.text,
          shared: e.shared
        }
      })
    },
    roleToTextMapping () {
      return this.$store.state.roles.roles.map((e: ProjectRole) => {
        return {
          text: e.description,
          value: e.id,
          icon: e.icon
        }
      })
    }
  },
  created () {
    this.$store.dispatch('services/getConfiguration')
  },
  methods: {
    async addServiceConfig () {
      if (this.addDialog.replaceId !== undefined) {
        await this.$store.dispatch('services/updateService', {
          id: this.addDialog.replaceId,
          data: {
            itemId: this.addDialog.itemId,
            role: this.addDialog.role,
            seconds: this.addDialog.seconds,
            shared: this.addDialog.shared,
            type: this.addDialog.pointType
          } as ServiceDataPutRequest
        })
      } else {
        await this.$store.dispatch('services/addService', {
          itemId: this.addDialog.itemId,
          role: this.addDialog.role,
          seconds: this.addDialog.seconds,
          shared: this.addDialog.shared,
          type: this.addDialog.pointType
        } as ServiceDataPostRequest)
      }
      this.addDialog.dialog = false
    },
    openDialog (itemName?: string) {
      if (itemName !== undefined) {
        this.addDialog.itemId = this.$store.state.items.services.find((e: ItemDTO) => e.name === itemName).id
      }
      this.addDialog.dialog = true
      this.addDialog.replaceId = undefined
    },
    openDialogAsReplace (id: number) {
      this.addDialog.replaceId = id
      this.addDialog.dialog = true
      // Find the right item
      const d = this.$store.state.services.serviceData.find((e: ServiceEntryDTO) => e.id === id)
      this.addDialog.itemId = d.item.id
      this.addDialog.role = d.role
      this.addDialog.pointType = d.pointType
      this.addDialog.seconds = d.seconds
      this.addDialog.shared = d.shared
    },
    openDeleteDialog (id: number) {
      this.deleteDialog.id = id
      this.deleteDialog.value = true
    },
    async deleteItem (id: number) {
      await this.$store.dispatch('services/deleteService', id)
    },
    closeDeleteDialog () {
      this.deleteDialog.id = -1
      this.deleteDialog.value = false
    },
    async confirmDeleteItem () {
      await this.$store.dispatch('services/deleteService', this.deleteDialog.id)
      this.deleteDialog.value = false
    }
  }
})
