
import StripGroupModel from '@/models/StripGroupModel'

export default {
  name: 'StripGroupChip',
  props: {
    stripGroup: {
      type: StripGroupModel
    },
    showColorOnSelect: {
      type: String,
      required: false
    }
  },
  methods: {
    getColor (i: number) {
      switch (i) {
        case 1:
          return 'pink'
        case 2:
          return 'purple'
        case 3:
          return 'indigo'
        case 4:
          return 'teal'
        case 5:
          return 'green'
      }
      return 'black'
    }
  }
}
