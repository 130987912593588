
import Vue from 'vue'
import { Bus } from '@/main'
import StripGroupChip from '@/components/StripGroupChip.vue'
import ConnectionStripConstructionDialog from '@/views/ConnectionStripConstructionDialog.vue'
import StripModel from '@/models/StripModel'
export default Vue.extend({
  name: 'StripTable',
  components: { StripGroupChip, ConnectionStripConstructionDialog },
  data: () => ({
    search: '',
    addDialog: false,
    addModel: new StripModel(),
    headers: [
      {
        text: 'ID (generiert)',
        sortable: false,
        value: 'id'
      },
      { text: 'Name', value: 'name', sortable: true },
      { text: 'Gruppe', value: 'stripGroup' },
      { text: 'Aktionen', value: 'actions' }
    ]
  }),
  computed: {
    strips () {
      return this.$store.state.strips
    },
    groups () {
      return this.$store.state.stripGroups
    }
  },
  methods: {
    openDialog (item: any) {
      Bus.$emit('open-strip-construction-dialog', item)
    },
    add () {
      if (this.addModel.stripGroupId < 0) {
        Bus.$emit('show-snackbar', 'Bitte eine Streifengruppe auswählen')
        return
      }
      const stripGroup = this.$store.state.stripGroups[this.addModel.stripGroupId]
      this.$store.dispatch('addStrip', {
        name: this.addModel.name,
        stripGroupId: stripGroup.id
      })
      this.addDialog = false
    },
    del (item: StripModel) {
      this.$store.dispatch('deleteStrip', item.id)
    }
  }
})
