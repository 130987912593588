import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        secondary: '#F07D00',
        primary: '#003C64',
        background: '#003C64'
      }
    }
  }
})
