import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import PlantView from '@/views/PlantView.vue'
import Store from '@/store/index'
import StripView from '@/views/StripView.vue'
import ConstructionTable from '@/components/ConstructionTable.vue'
import CountryTable from '@/components/CountryTable.vue'
import ItemView from '@/views/ItemView.vue'
import FunctionList from '@/views/FunctionList.vue'
import DictionaryView from '@/views/DictionaryView.vue'
import ServicesView from '@/views/ServicesView.vue'
import RolesView from '@/views/RolesView.vue'
import SettingsView from '@/views/SettingsView.vue'
import CleaningView from '@/views/CleaningView.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/plants',
    name: 'Anlagen',
    component: PlantView
  },
  {
    path: '/strips',
    name: 'Streifen',
    component: StripView
  },
  {
    path: '/constructions',
    name: 'Komponenten',
    component: ConstructionTable
  },
  {
    path: '/countries',
    name: 'Länder',
    component: CountryTable
  },
  {
    path: '/items',
    name: 'Artikel',
    component: ItemView
  },
  {
    path: '/functionlist',
    name: 'Funktionslisten',
    component: FunctionList
  },
  {
    path: '/descriptors',
    name: 'Wörterbuch',
    component: DictionaryView
  },
  {
    path: '/services',
    name: 'Dienstleistungen',
    component: ServicesView
  },
  {
    path: '/roles',
    name: 'Rollen',
    component: RolesView
  },
  {
    path: '/settings',
    name: 'Anwendungseinstellungen',
    component: SettingsView
  },
  {
    path: '/cleaning',
    name: 'Artikelprüfung',
    component: CleaningView
  }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Anlagen') {
    Store.dispatch('plantGroup/load')
    Store.dispatch('loadPlants')
  } else if (to.name === 'Streifen') {
    Store.dispatch('loadStrips')
    Store.dispatch('loadStripGroups')
  } else if (to.name === 'Komponenten') {
    Store.dispatch('units/load')
    Store.dispatch('functionList/loadIds')
    Store.dispatch('loadConstructions')
    Store.dispatch('constructionDetail/loadCustomConstructionId')
  } else if (to.name === 'Länder') {
    Store.dispatch('loadCountries')
  } else if (to.name === 'Home') {
    Store.dispatch('loadMetadata')
  } else if (to.name === 'Artikel') {
    Store.dispatch('items/loadFieldDevices')
    Store.dispatch('items/loadCabinets')
    Store.dispatch('items/loadDDCs')
    Store.dispatch('items/loadCables')
    Store.dispatch('items/loadServices')
    Store.dispatch('items/loadNotDefined')
    Store.dispatch('items/loadGlt')
  } else if (to.name === 'Funktionslisten') {
    Store.dispatch('functionList/load')
  } else if (to.name === 'Wörterbuch') {
    Store.dispatch('dictionary/loadDescriptions')
  } else if (to.name === 'Dienstleistungen') {
    Store.dispatch('items/loadServices')
    Store.dispatch('roles/getRoles')
  } else if (to.name === 'Rollen') {
    Store.dispatch('roles/getRoles')
  }
  next()
})

export default router
