import Vue from 'vue'
import { Module } from 'vuex'
import PlantGroup from '@/models/PlantGroup'

const plantGroup: Module<any, any> = {
  namespaced: true,
  state: () => ({
    plantGroups: Array<PlantGroup>()
  }),
  mutations: {
    setPlantGroups (state, plantGroups: []) {
      state.plantGroups = plantGroups
    }
  },
  actions: {
    load ({ commit }) {
      Vue.axios
        .get('macrolib/plantgroups')
        .then((result) => {
          commit('setPlantGroups', result.data)
        })
    }
  }
}
export default plantGroup
