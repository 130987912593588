<template>
  <v-dialog width="600px" v-model="specificationDialog">
    <v-card>
      <v-card-title>
        <v-toolbar-title>{{ $t('actions.specifications', {edit:editMode?'bearbeiten':'hinzufügen'}) }}</v-toolbar-title>
        <v-spacer/>
        <v-btn @click="specificationDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="currentSpecType">
          <v-radio :key="s.label" :label="s.label" :value="s.value" v-for="s in specificationTypes"></v-radio>
        </v-radio-group>
        <v-row>
          <v-col :cols="currentSpecType === 'RANGE'?6:12" v-if="specificationDialogProperties !== undefined">
            <v-text-field v-if="currentSpecType !== 'TEXT'" v-model="reference.minimalValue" :type="specificationDialogProperties.type" :label="specificationDialogProperties.label">
            </v-text-field>
            <v-text-field v-else v-model="reference.stringValue" :type="specificationDialogProperties.type" :label="specificationDialogProperties.label">
            </v-text-field>
          </v-col>
          <v-col cols="6" v-if="currentSpecType === 'RANGE'">
            <v-text-field v-model="reference.maximalValue" type="number" :label="$t('dialog.maximumValue')">
            </v-text-field>
          </v-col>
          <v-col>
            <v-checkbox v-model="reference.default" :label="$t('dialog.setAsStandard')"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" text color="green">{{ $t('actions.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SpecificationModel from '@/models/SpecificationModel'
import Vue from 'vue'
import { Bus } from '@/main'

export default Vue.extend({
  name: 'SpecificationDialog',
  created () {
    Bus.$on('open-specification-dialog', (id) => {
      if (id === -1) {
        this.$store.dispatch('specificationDetail/resetSpecification').then(() => {
          console.log(this.reference)
          this.currentSpecType = this.reference.specificationType
          this.specificationDialog = true
          this.editMode = true
        })
      } else {
        this.$store.dispatch('specificationDetail/loadSpecification', id).then(() => {
          console.log(this.reference)
          this.currentSpecType = this.reference.specificationType
          this.specificationDialog = true
          this.editMode = true
        })
      }
    })
  },
  data: () => ({
    specificationDialog: false,
    // TODO: Key Value System für Typen     NUMERIC, DECIMAL, RANGE, TEXT, VARIANT
    specificationTypes: [
      {
        value: 'NUMERIC',
        label: 'Ganzzahl'
      },
      {
        value: 'DECIMAL',
        label: 'Gleitkommazahl'
      },
      {
        value: 'RANGE',
        label: 'Zahlenbereich'
      },
      {
        value: 'TEXT',
        label: 'Freitext'
      },
      {
        value: 'VARIANT',
        label: 'Ohne Text'
      }
    ],
    currentSpecType: 'TEXT',
    minValue: 0,
    loading: false
  }),
  computed: {
    editMode () {
      return this.$store.state.specificationDetail.specification.id !== -1
    },
    reference () {
      return this.$store.state.specificationDetail.specification
    },
    specificationDialogProperties () {
      switch (this.currentSpecType) {
        case 'NUMERIC':
          return {
            label: 'Ganzzahl',
            type: 'number',
            model: this.reference.minimalValue
          }
        case 'DECIMAL':
          return {
            label: 'Gleitkommazahl',
            type: 'number',
            model: this.reference.minimalValue
          }
        case 'TEXT':
          return {
            label: 'Freitext',
            type: 'text',
            model: this.reference.stringValue
          }
        case 'RANGE':
          return {
            label: 'Minimalwert',
            type: 'number',
            model: this.reference.minimalValue
          }
        default:
          return undefined
      }
    }
  },
  methods: {
    save () {
      if (this.reference.refId === -1) {
        this.reference.refId = null
      }
      if (this.reference.id === -1) {
        this.$store.dispatch('specificationDetail/createSpecification', {
          id: this.reference.id,
          object: {
            stringValue: this.reference.stringValue,
            minimalValue: this.reference.minimalValue,
            maximalValue: this.reference.maximalValue,
            specificationType: this.currentSpecType,
            isDefault: this.reference.default,
            constructionId: this.$store.state.constructionDetail.construction.id,
            refId: this.reference.refId
          }
        }).then(() => {
          this.specificationDialog = false
          this.$store.dispatch('constructionDetail/update')
        })
      } else {
        this.$store.dispatch('specificationDetail/updateSpecification', {
          id: this.reference.id,
          object: {
            refId: this.reference.refId,
            stringValue: this.reference.stringValue,
            minimalValue: this.reference.minimalValue,
            maximalValue: this.reference.maximalValue,
            specificationType: this.currentSpecType,
            isDefault: this.reference.default
          }
        }).then(() => {
          this.specificationDialog = false
          this.$store.dispatch('constructionDetail/update')
        })
      }
    }
  }
})
</script>

<style scoped>

</style>
