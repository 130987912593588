<template>
  <v-container>
    <template v-for="stripGroup in stripGroups">
      <strip-group-chip class="mr-2" :strip-group="stripGroup" v-bind:key="stripGroup.id"></strip-group-chip>
    </template>
  </v-container>
</template>

<script>
import Vue from 'vue'
import StripGroupChip from '@/components/StripGroupChip'
export default Vue.extend({
  name: 'StripGroups',
  components: { StripGroupChip },
  computed: {
    stripGroups () {
      return this.$store.state.stripGroups
    }
  }
})
</script>

<style scoped>

</style>
