import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import PlantModel from '@/models/PlantModel'
import StripModel from '@/models/StripModel'
import StripGroupModel from '@/models/StripGroupModel'
import VueAxios from 'vue-axios'
import ConstructionModel from '@/models/ConstructionModel'
import CountryModel from '@/models/CountryModel'
import PlantStripRelationModel from '@/models/PlantStripRelationModel'
import PlantConstructionRelationModel from '@/models/PlantConstructionRelationModel'
import DescriptionModel from '@/models/DescriptionModel'
import StripConstructionRelationModel from '@/models/StripConstructionRelationModel'
import constructionDetail from '@/store/construction-detail'
import specificationDetail from '@/store/specification-detail'
import items from '@/store/items'
import units from '@/store/units'
import settings from '@/store/settings'
import functionList from '@/store/functionlist'
import plantGroup from '@/store/plantgroup'
import { roles } from '@/store/roles'
import dictionary from '@/store/dictionary'
import services from '@/store/services'
import MetadataModel from '@/models/MetadataModel'
import auth from '@/authentication'
import { DetailUserModel } from '@/models/UserModel'
import { BACKEND_URI, APPLICATION_ID } from '@/utils/env'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = BACKEND_URI

axios.interceptors.request.use(
  async config => {
    return auth.acquireToken(APPLICATION_ID).then(token => {
      if (config.headers !== undefined) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }).catch(error => {
      console.log(error)
      return config
    })
  }
)

export default new Vuex.Store({
  state: {
    plants: Array<PlantModel>(),
    strips: Array<StripModel>(),
    plantConstructions: Array<PlantConstructionRelationModel>(),
    plantStrips: Array<PlantStripRelationModel>(),
    stripGroups: Array<StripGroupModel>(),
    stripConstructions: Array<StripConstructionRelationModel>(),
    constructions: Array<ConstructionModel>(),
    descriptions: Array<DescriptionModel>(),
    countries: Array<CountryModel>(),
    metadata: new MetadataModel(),
    isInNetwork: false,
    currentUser: DetailUserModel
  },
  mutations: {
    setPlants (state, plants: [PlantModel]) {
      state.plants = plants
    },
    setStrips (state, strips: [StripModel]) {
      state.strips = strips
    },
    addStrip (state, strip: StripModel) {
      state.strips.push(strip)
    },
    deleteStrip (state, id) {
      state.strips = state.strips.filter((i) => {
        return i.id !== id
      })
    },
    setPlantConstructions (state, constructions: [PlantConstructionRelationModel]) {
      state.plantConstructions = constructions
    },
    addPlantConstruction (state, construction: PlantConstructionRelationModel) {
      state.plantConstructions.push(construction)
    },
    changePlantConstruction (state, construction: PlantConstructionRelationModel) {
      const removeIndex = state.plantConstructions.findIndex(item => item.id === construction.id)
      state.plantConstructions[removeIndex] = construction
    },
    setPlantStrips (state, strips: [PlantStripRelationModel]) {
      state.plantStrips = strips
    },
    addPlantStrip (state, strip: PlantStripRelationModel) {
      state.plantStrips.push(strip)
    },
    changePlantStrip (state, strip: PlantStripRelationModel) {
      const removeIndex = state.plantStrips.findIndex(item => item.id === strip.id)
      state.plantStrips[removeIndex] = strip
    },
    changePlant (state, plant: PlantModel) {
      const item = state.plants.find(item => item.id === plant.id)
      Object.assign(item, plant)
    },
    deletePlantStrip (state, id: number) {
      const removeIndex = state.plantStrips.findIndex(item => item.id === id)
      state.plantStrips.splice(removeIndex, 1)
    },
    setStripConstructions (state, stripConstructions: [StripConstructionRelationModel]) {
      state.stripConstructions = stripConstructions
    },
    addStripConstruction (state, stripConstruction: StripConstructionRelationModel) {
      state.stripConstructions.push(stripConstruction)
    },
    updateStripConstruction (state, stripConstruction: StripConstructionRelationModel) {
      const removeIndex = state.stripConstructions.findIndex(item => item.id === stripConstruction.id)
      state.stripConstructions[removeIndex] = stripConstruction
    },
    setStripGroups (state, groups: [StripGroupModel]) {
      state.stripGroups = groups
    },
    setConstructions (state, constructions: [ConstructionModel]) {
      state.constructions = constructions
    },
    addConstruction (state, construction: ConstructionModel) {
      state.constructions.push(construction)
    },
    patchConstruction (state, construction: ConstructionModel) {
      const i = state.constructions.findIndex((i: ConstructionModel) => i.id === construction.id)
      if (i === -1) {
        return
      }
      state.constructions[i] = construction
      state.constructions = [...state.constructions]
    },
    setCountries (state, countries: [CountryModel]) {
      state.countries = countries
    },
    addPlant (state, plant: PlantModel) {
      state.plants.push(plant)
    },
    deletePlant (state, id: number) {
      const removeIndex = state.plants.findIndex(item => item.id === id)
      state.plants.splice(removeIndex, 1)
    },
    deletePlantConstruction (state, id: number) {
      const removeIndex = state.plantConstructions.findIndex(item => item.id === id)
      state.plantConstructions.splice(removeIndex, 1)
    },
    setDescriptions (state, descriptions) {
      state.descriptions = descriptions
    },
    setMetadata (state, dat) {
      state.metadata = dat
    },
    setIsInNetwork (state, s: boolean) {
      state.isInNetwork = s
    },
    setCurrentUser (state, userModel: typeof DetailUserModel) {
      state.currentUser = userModel
    }
  },
  actions: {
    loadDefaultUser ({ commit }) {
      return new Promise<DetailUserModel>((resolve, reject) => {
        Vue.axios.get('users/me')
          .then(result => {
            commit('setCurrentUser', result.data)
            commit('setIsInNetwork', true)
            resolve(result.data)
          })
          .catch(err => {
            commit('setIsInNetwork', false)
            reject(err.message)
          })
      })
    },
    checkNetwork ({ commit }) {
      return new Promise<boolean>((resolve, reject) => {
        Vue.axios.get('')
          .then(result => {
            console.log(result)
            commit('setIsInNetwork', true)
            resolve(true)
          })
          .catch((err) => {
            console.log(err)
            commit('setIsInNetwork', false)
            reject(err.message)
          })
      })
    },
    loadPlants ({ commit }) {
      return new Promise<PlantModel[]>((resolve, reject) => {
        Vue.axios
          .get('macrolib/plants')
          .then(result => {
            commit('setPlants', result.data)
            resolve(result.data)
          })
      })
    },
    loadStrips ({ commit }) {
      Vue.axios
        .get('macrolib/strips')
        .then(result => {
          commit('setStrips', result.data)
        })
    },
    addStrip ({ commit }, payload) {
      Vue.axios
        .post('macrolib/strips', payload)
        .then(result => {
          commit('addStrip', result.data)
        })
    },
    deleteStrip ({ commit }, id) {
      Vue.axios
        .delete(`macrolib/strips/${id}`)
        .then(result => {
          commit('deleteStrip', id)
        })
    },
    loadPlantConstructions ({ commit }, id) {
      console.log(`plants/${id}/constructions`)
      return new Promise<ConstructionModel[]>((resolve, reject) => {
        Vue.axios
          .get(`macrolib/plants/${id}/constructions`)
          .then(result => {
            commit('setPlantConstructions', result.data)
            resolve(result.data)
          })
      })
    },
    loadMetadata ({ commit }) {
      return new Promise<void>((resolve) => {
        Vue.axios
          .get('macrolib/metadata')
          .then(result => {
            commit('setMetadata', result.data)
            resolve()
          })
      })
    },
    addPlantConstruction ({ commit }, { id, entity }) {
      console.warn(`macrolib/plants/${id}/constructions`, entity)
      return new Promise<PlantConstructionRelationModel>((resolve) => {
        console.log(`macrolib/plants/${id}/constructions/`)
        Vue.axios
          .post(`macrolib/plants/${id}/constructions`, entity)
          .then(result => {
            commit('addPlantConstruction', result.data)
            resolve(result.data)
          })
      })
    },
    changePlantConstruction ({ commit }, { id, entity }) {
      return new Promise<PlantConstructionRelationModel>((resolve) => {
        console.warn(`macrolib/plants/${id}/constructions/${entity.id}`)
        Vue.axios
          .put(`macrolib/plants/${id}/constructions/${entity.id}`, entity)
          .then(result => {
            commit('changePlantConstruction', result.data)
            console.log('Ein kleiner Test')
            resolve(result.data)
          })
      })
    },
    deletePlantConstruction ({ commit }, { id, entity }) {
      return new Promise<void>((resolve) => {
        Vue.axios
          .delete((`macrolib/plants/${id}/constructions/${entity.id}`))
          .then(() => {
            // TODO: Delete
            commit('deletePlantConstruction', entity.id)
            resolve()
          })
      })
    },
    addPlant ({ commit }, plant: PlantModel) {
      return new Promise<PlantModel>((resolve, reject) => {
        Vue.axios
          .post('macrolib/plants', plant)
          .then((result) => {
            commit('addPlant', result.data)
            resolve(result.data)
          }, err => {
            reject(err)
          })
      })
    },
    editPlant ({ commit }, plant: PlantModel) {
      return new Promise<PlantModel>((resolve, reject) => {
        Vue.axios
          .put(`macrolib/plants/${plant.id}`, plant)
          .then((result) => {
            commit('changePlant', result.data)
            console.table(result.data)
            resolve(result.data)
          }, err => {
            reject(err)
          })
      })
    },
    deletePlant ({ commit }, id: number) {
      return new Promise<void>((resolve, reject) => {
        Vue.axios
          .delete(`macrolib/plants/${id}`)
          .then((result1) => {
            commit('deletePlant', id)
            resolve()
          }, err => {
            reject(err)
          })
      })
    },
    loadPlantStrips ({ commit }, id) {
      return new Promise<void>((resolve, reject) => {
        Vue.axios
          .get(`macrolib/plants/${id}/strips`)
          .then(result => {
            commit('setPlantStrips', result.data)
            resolve(result.data)
            console.log('YEAAH', result.data)
          })
      })
    },
    addPlantStrip ({ commit }, { id, entity }) {
      return new Promise<PlantStripRelationModel>((resolve) => {
        Vue.axios
          .post(`macrolib/plants/${id}/strips`, entity)
          .then(result => {
            commit('addPlantStrip', result.data)
            resolve(result.data)
          })
      })
    },
    changePlantStrip ({ commit }, { id, entity }) {
      return new Promise<PlantStripRelationModel>((resolve) => {
        Vue.axios
          .put(`macrolib/plants/${id}/strips/${entity.id}`, entity)
          .then(result => {
            commit('changePlantStrip', result.data)
            resolve(result.data)
          })
      })
    },
    deletePlantStrip ({ commit }, { id, entity }) {
      return new Promise<void>((resolve) => {
        Vue.axios
          .delete((`macrolib/plants/${id}/strips/${entity.id}`))
          .then(() => {
            commit('deletePlantStrip', entity.id)
            resolve()
          })
      })
    },
    loadStripGroups ({ commit }) {
      Vue.axios
        .get('macrolib/strips/groups')
        .then(result => {
          commit('setStripGroups', result.data)
        })
    },
    loadConstructions ({ commit }) {
      Vue.axios
        .get('macrolib/constructions')
        .then(result => {
          const data = result.data.sort((a: ConstructionModel, b: ConstructionModel) => {
            a.name.localeCompare(b.name)
          })
          commit('setConstructions', data)
        })
    },
    patchConstruction ({ commit }, { id, payload }) {
      return new Promise<ConstructionModel>(resolve => {
        Vue.axios
          .patch(`macrolib/constructions/${id}`, payload)
          .then(result => {
            commit('patchConstruction', result.data)
            // Reload list
            resolve(result.data)
          })
      })
    },
    addConstruction ({ commit }, { payload }) {
      return new Promise<ConstructionModel>(resolve => {
        Vue.axios
          .post('macrolib/constructions', payload)
          .then(result => {
            commit('addConstruction', result.data)
            // Reload list
            resolve(result.data)
          })
      })
    },
    loadDescriptions ({ commit }) {
      Vue.axios
        .get('macrolib/descriptions')
        .then(result => {
          commit('setDescriptions', result.data)
        })
    },
    loadCountries ({ commit }) {
      Vue.axios
        .get('common/countries')
        .then(result => {
          commit('setCountries', result.data)
        })
    },
    loadStripConstructions ({ commit }, id) {
      return new Promise<void>((resolve, reject) => {
        Vue.axios
          .get(`macrolib/strips/${id}/constructions`)
          .then(result => {
            commit('setStripConstructions', result.data)
            resolve(result.data)
          })
      })
    },
    addStripConstruction ({ commit }, { id, entity }) {
      return new Promise<StripConstructionRelationModel>((resolve) => {
        Vue.axios
          .post(`macrolib/strips/${id}/constructions`, entity)
          .then(result => {
            commit('addStripConstruction', result.data)
            resolve(result.data)
          })
      })
    },
    changeStripConstruction ({ commit }, { id, entity }) {
      return new Promise<StripConstructionRelationModel>((resolve) => {
        Vue.axios
          .put(`macrolib/strips/${id}/constructions/${entity.id}`, entity)
          .then(result => {
            commit('updateStripConstruction', result.data)
            resolve(result.data)
          })
      })
    }
  },
  modules: {
    items, constructionDetail, specificationDetail, units, functionList, plantGroup, dictionary, services, roles, settings
  }
})
