
import Vue from 'vue'
import StripTable from '@/components/StripTable.vue'
import StripGroups from '@/components/StripGroups.vue'
export default Vue.extend({
  name: 'StripView',
  components: { StripTable, StripGroups },
  data: () => ({
    tabs: null
  })
})
