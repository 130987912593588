
import { ProjectRole, ProjectRolePostRequest, ProjectRolePutRequest } from '@/__generated__'
import Vue from 'vue'
import { formatPercentage } from '@/utils/stringFormatting'
const regex = /^[A-Z]+$/
const mdiRegex = /^mdi-[a-z-]+$/
export default Vue.extend({
  data: () => ({
    addDialog: {
      dialog: false,
      modifyingId: undefined as string | undefined,
      data: {
        id: '',
        defaultPercentage: 0,
        switchCabinetPercentage: 0,
        switchCabinetAndElectricPercentage: 0,
        description: '',
        navItem: '' as any | undefined,
        tableHeader: '' as string | undefined,
        icon: '' as string | undefined
      }
    },
    deleteDialog: {
      value: false,
      id: undefined as string | undefined
    },
    valid: false,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Beschreibung', value: 'description' },
      { text: 'Nav-Verknüpfung', value: 'erpName' },
      { text: 'Tabellenüberschrift', value: 'tableHeader' },
      { text: 'Icon', value: 'icon' },
      { text: 'Prozentsatz', value: 'defaultPercentage' },
      { text: 'Prozentsatz (Schaltschrank)', value: 'switchCabinetPercentage' },
      { text: 'Prozentsatz (Schaltschrank & Elektro)', value: 'switchCabinetAndElectricPercentage' },
      { text: 'Aktionen', value: 'actions', width: '120px' }
    ],
    percentageRule: (value: string | undefined) => (value !== undefined && !isNaN(parseFloat(value)) && parseFloat(value) >= 0 && parseFloat(value) <= 100) || 'Dieser Wert muss zwischen 0 und 100% liegen.',
    requiredRule: (value: string | undefined) => (value !== undefined && value.length > 0) || 'Dieses Feld darf nicht leer sein.',
    onlyCapitals: (value: string | undefined) => (value !== undefined && regex.test(value)) || 'Bitte nur Großbuchstaben.',
    mdiCheck: (value: string | undefined) => (value !== undefined && mdiRegex.test(value)) || 'Bitte im Format "mdi-[icon-name]" ausfüllen.'
  }),
  methods: {
    formatPercentage (input: number) {
      return formatPercentage(input)
    },
    async save () {
      if (this.addDialog.modifyingId === undefined) {
        // Post
        const data = {
          id: this.addDialog.data.id,
          description: this.addDialog.data.description,
          erpName: this.addDialog.data.navItem,
          icon: this.addDialog.data.icon,
          tableHeader: this.addDialog.data.tableHeader,
          defaultPercentage: this.addDialog.data.defaultPercentage / 100,
          switchCabinetPercentage: this.addDialog.data.switchCabinetPercentage / 100,
          switchCabinetAndElectricPercentage: this.addDialog.data.switchCabinetAndElectricPercentage / 100
        } as ProjectRolePostRequest
        await this.$store.dispatch('roles/addRole', data)
        this.addDialog.dialog = false
      } else {
        // Put
        const request = {
          description: this.addDialog.data.description,
          erpName: this.addDialog.data.navItem,
          icon: this.addDialog.data.icon,
          tableHeader: this.addDialog.data.tableHeader,
          defaultPercentage: this.addDialog.data.defaultPercentage / 100,
          switchCabinetPercentage: this.addDialog.data.switchCabinetPercentage / 100,
          switchCabinetAndElectricPercentage: this.addDialog.data.switchCabinetAndElectricPercentage / 100
        } as ProjectRolePutRequest
        await this.$store.dispatch('roles/updateRole', { id: this.addDialog.modifyingId, request })
        this.addDialog.dialog = false
      }
    },
    edit (ele: ProjectRole) {
      this.addDialog.dialog = true
      this.addDialog.modifyingId = ele.id
      this.addDialog.data.description = ele.description
      this.addDialog.data.id = ele.id
      this.addDialog.data.icon = ele.icon
      this.addDialog.data.defaultPercentage = ele.defaultPercentage * 100
      this.addDialog.data.switchCabinetPercentage = ele.switchCabinetPercentage * 100
      this.addDialog.data.switchCabinetAndElectricPercentage = ele.switchCabinetAndElectricPercentage * 100
      this.addDialog.data.navItem = ele.erpName
      this.addDialog.data.tableHeader = ele.tableHeader
    },
    add () {
      this.addDialog.dialog = true
      this.addDialog.data.description = ''
      this.addDialog.data.id = ''
      this.addDialog.data.icon = ''
      this.addDialog.data.navItem = ''
      this.addDialog.data.tableHeader = ''
      this.addDialog.data.defaultPercentage = 0
      this.addDialog.data.switchCabinetPercentage = 0
      this.addDialog.data.switchCabinetAndElectricPercentage = 0
      this.addDialog.modifyingId = undefined
    },
    showDeleteDialog (id: string) {
      // DELETE
      this.deleteDialog.value = true
      this.deleteDialog.id = id
    },
    async confirmDelete () {
      //
      await this.$store.dispatch('roles/deleteRole', this.deleteDialog.id)
      this.closeDeleteDialog()
    },
    closeDeleteDialog () {
      this.deleteDialog.value = false
      this.deleteDialog.id = undefined
    }
  }
})
