
import Vue from 'vue'
import { Bus } from '@/main'
import PlantModel from '@/models/PlantModel'
import PlantDialog from '@/components/PlantDialog.vue'
import PlantGroup from '@/models/PlantGroup'
export default Vue.extend({
  name: 'PlantTable',
  components: { PlantDialog },
  data: () => ({
    text: '',
    formTitle: '',
    dialogDelete: false,
    deleteItem: new PlantModel(),
    snackbar: false,
    dialog: false,
    editedItem: new PlantModel(),
    search: '',
    headers: [
      { text: 'Status', value: 'finished', width: '10%', sortable: false },
      {
        text: 'ID (generiert)',
        sortable: false,
        value: 'id',
        width: '5%'
      },
      { text: 'Name', width: '40%', value: 'name', sortable: false },
      { text: 'Makro-ID', value: 'macroId', align: 'start', sortable: false },
      { text: 'Gewerk', value: 'plantGroupId' },
      { text: 'PID', value: 'pid', sortable: false },
      { text: 'HZG', value: 'hzg', sortable: false },
      { text: 'FW', value: 'fw', sortable: false },
      { text: 'Aktionen', value: 'actions', width: '15%', sortable: false }
    ]
  }),
  computed: {
    plants () {
      return this.$store.state.plants
    },
    isAdmin () {
      return this.$store.state.currentUser.isAdmin
    }
  },
  methods: {
    getPlantText (id: number) {
      const p = this.$store.state.plantGroup.plantGroups.find((p: PlantGroup) => {
        return p.id === id
      })
      return `${p.groupId} ${p.short}`
    },
    getStatus (status: boolean) {
      return status ? 'Fertig' : 'In Arbeit'
    },
    addItem () {
      this.editedItem = new PlantModel()
      Bus.$emit('tunePlant', this.editedItem)
    },
    editItem (item: PlantModel) {
      this.editedItem = JSON.parse(JSON.stringify(item))
      Bus.$emit('tunePlant', this.editedItem)
    },
    openPlantDetail (item: PlantModel) {
      console.log('Opening plant dialog', item)
      Bus.$emit('open-plant-dialog', item)
    },
    openDeleteDialog (item: PlantModel) {
      this.deleteItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.$store.dispatch('deletePlant', this.deleteItem.id).then(() => {
        this.text = `Anlage ${this.deleteItem.name} wurde gelöscht`
        this.snackbar = true
        this.dialogDelete = false
      })
    },
    close () {
      this.dialog = false
    }
  }
})
