
import { changeRoleItemPercentage } from '@/api'
import { formatPercentage } from '@/utils/stringFormatting'
import { ProjectRole } from '@/__generated__'
import { RoleItemPercentageDTO } from '@/__generated__/models/RoleItemPercentageDTO'
import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    minMaxRule: (value: string) => (parseInt(value) > 0 && parseInt(value) <= 100) || 'Muss größer als 0 und darf maximal 100 sein.',
    mustBeSelectedRule: (value: string | undefined) => value !== undefined || 'Darf nicht leer sein.',
    valid: false,
    headers: [
      {
        text: 'Item',
        value: 'item'
      },
      {
        text: 'Kennzahl in Prozent',
        value: 'percentage'
      },
      {
        text: 'Aktionen',
        value: 'actions',
        width: '120px'
      }
    ],
    addDialog: {
      replaceId: undefined as number | undefined,
      dialog: false,
      itemId: undefined as number | undefined,
      role: 'MANAGER',
      percentage: '' as string
    }
  }),
  computed: {
    items () : RoleItemPercentageDTO[] {
      return this.$store.state.services.percentages
    },
    roleToTextMapping () {
      return this.$store.state.roles.roles.map((e: ProjectRole) => {
        return {
          text: e.description,
          value: e.id,
          icon: e.icon
        }
      })
    }
  },
  created () {
    this.$store.dispatch('services/getPercentages')
  },
  methods: {
    getRoleById (id: string) {
      return this.items.find((e) => e.role.id === id)?.role
    },
    getCurrentPercentageSum (id: string) {
      return this.items
        .filter((e) => e.role.id === id)
        .reduce((sum: number, current: RoleItemPercentageDTO) => sum + current.percentage, 0)
    },
    formatPercentage (input: number) {
      return formatPercentage(input)
    },
    async addRoleItemPercentage () {
      const val = parseFloat(this.addDialog.percentage)
      if (isNaN(val)) {
        return
      }
      const sentVal = val / 100
      await this.$store.dispatch('services/addPercentage', {
        request: {
          itemId: this.addDialog.itemId,
          roleId: this.addDialog.role,
          percentage: sentVal
        }
      })
      this.addDialog.dialog = false
    },
    async changeRoleItemPercentage () {
      const val = parseFloat(this.addDialog.percentage)
      if (isNaN(val)) {
        return
      }
      const sentVal = val / 100
      await this.$store.dispatch('services/changePercentage', {
        id: this.addDialog.replaceId,
        request: {
          itemId: this.addDialog.itemId,
          roleId: this.addDialog.role,
          percentage: sentVal
        }
      })
      this.addDialog.dialog = false
    },
    async deleteById (id: number) {
      await this.$store.dispatch('services/deletePercentage', id)
    },
    openDialog (id: number | undefined) {
      this.addDialog.replaceId = id
      if (id !== undefined) {
        const item = this.items.find((i: RoleItemPercentageDTO) => i.id === id)
        this.addDialog.itemId = item?.item.id
        const perc = (item?.percentage ?? 0) * 100
        this.addDialog.percentage = perc.toString()
        this.addDialog.role = item?.role.id ?? ''
      }
      this.addDialog.dialog = true
    }
  }
})
