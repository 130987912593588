<template>
  <v-layout column>
    <div class="text-overline">
      <span v-text="relationController.title"></span>
      <v-btn @click="relationController.newItem()" v-if="isAdmin" color="pink" icon><v-icon>mdi-plus</v-icon></v-btn>
      <v-btn :disabled="relationController.equalsStore()" v-if="isAdmin" @click="save" text color="blue">{{ $t('actions.save') }}</v-btn>
    </div>
    <v-data-table sort-by="id" :items="items" :headers="headers">
      <template v-slot:item.orderBy="{ item }">
        <v-text-field v-model="item.orderBy"></v-text-field>
      </template>
      <template v-slot:item.id="{ item }">
        <span>{{item.id > -1?item.id:'Neu'}}</span>
        <v-badge :color="item.id > 0?'blue':'orange'" offset-y="-6" offset-x="-1" dot v-if="!relationController.equalsStoreItem(item)"></v-badge>
      </template>
      <template v-slot:item.construction="{ item }">
        <v-autocomplete :disabled="!isAdmin" :items="relationController.names()" v-model="item.construction.id" item-value="id" item-text="name"></v-autocomplete>
      </template>
      <template v-slot:item.strip="{ item }">
        <v-autocomplete :disabled="!isAdmin" :items="relationController.names()" v-model="item.strip.id" item-value="id" item-text="name"></v-autocomplete>
      </template>
      <template v-slot:item.description="{ item }">
        <v-autocomplete :disabled="!isAdmin" auto-select-first :items="descriptions" v-model="item.description.id" item-value="id" item-text="description"></v-autocomplete>
      </template>
      <template v-if="isAdmin" v-slot:item.actions="{ item }">
        <v-btn @click="relationController.revertItem(item)" :disabled="relationController.equalsStoreItem(item)" icon><v-icon>mdi-undo</v-icon></v-btn>
        <v-btn @click="del(item)" icon><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import { Bus } from '@/main'

import IRelationController from '@/controllers/IRelationController'

export default Vue.extend({
  created () {
    // eslint-disable-next-line no-unused-expressions
    this.$props.relationController?.init()
  },
  computed: {
    items () {
      return this.$props.relationController.modified
    },
    headers () {
      return this.$props.relationController.headers
    },
    descriptions () {
      return this.$props.relationController.descriptions()
    },
    isAdmin () {
      return this.$store.state.currentUser.isAdmin
    }
  },
  methods: {
    del (item) {
      // eslint-disable-next-line no-unused-expressions
      this.$props.relationController?.delete(item)
    },
    save () {
      // eslint-disable-next-line no-unused-expressions
      this.relationController?.commitChanges()
    }
  },
  props: {
    relationController: IRelationController
  }
})
</script>

<style scoped>

</style>
