import Vue from 'vue'
import axios from 'axios'
import { Module } from 'vuex'
import ItemModel from '@/models/ItemModel'
import { ItemUsageDTO, ItemService, ItemRequest } from '@/__generated__'

const items: Module<any, any> = {
  namespaced: true,
  state: () => ({
    fielddevices: [] as ItemModel[],
    cables: [] as ItemModel[],
    cabinets: [] as ItemModel[],
    ddcs: [] as ItemModel[],
    services: [] as ItemModel[],
    glt: [] as ItemModel[],
    notDefined: [] as ItemModel[],
    itemAvailability: undefined as ItemUsageDTO | undefined
  }),
  mutations: {
    setItemAvailability (state, availability: ItemUsageDTO | undefined) {
      state.itemAvailability = availability
    },
    setFieldDevices (state, items: ItemModel[]) {
      state.fielddevices = items
    },
    setCables (state, items: ItemModel[]) {
      state.cables = items
    },
    setCabinets (state, items: ItemModel[]) {
      state.cabinets = items
    },
    setDDCs (state, items: ItemModel[]) {
      state.ddcs = items
    },
    setServices (state, items: ItemModel[]) {
      state.services = items
    },
    setGlt (state, items: ItemModel[]) {
      state.glt = items
    },
    setNotDefined (state, items: ItemModel[]) {
      state.notDefined = items
    },
    addItem (state, { type, data }) {
      switch (type) {
        case 'FIELD_DEVICE':
          state.fielddevices.push(data)
          break
        case 'CABLE':
          state.cables.push(data)
          break
        case 'SWITCH_CABINET':
          state.cabinets.push(data)
          break
        case 'DDC':
          state.ddcs.push(data)
          break
        case 'SERVICE':
          state.services.push(data)
          break
        case 'NOT_DEFINED':
          state.notDefined.push(data)
          break
        case 'GLT':
          state.glt.push(data)
          break
      }
    },
    updateItem (state, { data, type } : { data: ItemModel, type: string }) {
      let currentArray
      switch (type) {
        case 'FIELD_DEVICE':
          currentArray = state.fielddevices
          break
        case 'CABLE':
          currentArray = state.cables
          break
        case 'SWITCH_CABINET':
          currentArray = state.cabinets
          break
        case 'DDC':
          currentArray = state.ddcs
          break
        case 'SERVICE':
          currentArray = state.services
          break
        case 'NOT_DEFINED':
          currentArray = state.notDefined
          break
        case 'GLT':
          currentArray = state.glt
          break
      }
      const index = currentArray.findIndex((item: ItemModel) => item.id === data.id)
      if (index === -1) {
        return
      }
      currentArray[index] = data
    },
    deleteItem (state, { type, data }) {
      let currentArray
      switch (type) {
        case 'FIELD_DEVICE':
          currentArray = state.fielddevices
          break
        case 'CABLE':
          currentArray = state.cables
          break
        case 'SWITCH_CABINET':
          currentArray = state.cabinets
          break
        case 'DDC':
          currentArray = state.ddcs
          break
        case 'SERVICE':
          currentArray = state.services
          break
        case 'NOT_DEFINED':
          currentArray = state.notDefined
          break
        case 'GLT':
          currentArray = state.glt
          break
      }
      console.warn(currentArray)
      const removeIndex = currentArray.findIndex((item: ItemModel) => item.id === data.id)
      currentArray.splice(removeIndex, 1)
    }
  },
  actions: {
    loadFieldDevices ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/fielddevices')
          .then(result => {
            commit('setFieldDevices', result.data)
            resolve(result.data)
          })
      })
    },
    loadItemAvailability ({ commit }) {
      commit('setItemAvailability', undefined)
      return new Promise<ItemUsageDTO>((resolve) => {
        Vue.axios
          .get('items/check-item-availability')
          .then(result => {
            commit('setItemAvailability', result.data)
            resolve(result.data)
          })
      })
    },
    loadCables ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/cables')
          .then(result => {
            commit('setCables', result.data)
            resolve(result.data)
          })
      })
    },
    loadCabinets ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/cabinets')
          .then(result => {
            commit('setCabinets', result.data)
            resolve(result.data)
          })
      })
    },
    loadDDCs ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/ddc/include-auto-layout')
          .then(result => {
            commit('setDDCs', result.data)
            resolve(result.data)
          })
      })
    },
    loadServices ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/services')
          .then(result => {
            commit('setServices', result.data)
            resolve(result.data)
          })
      })
    },
    loadGlt ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/glt')
          .then(result => {
            commit('setGlt', result.data)
            resolve(result.data)
          })
      })
    },
    loadNotDefined ({ commit }) {
      return new Promise<ItemModel[]>((resolve, reject) => {
        Vue.axios
          .get('items/other')
          .then(result => {
            commit('setNotDefined', result.data)
            resolve(result.data)
          })
      })
    },
    async addItem ({ commit }, payload: ItemRequest) {
      const result = await ItemService.addItem({ requestBody: payload })
      commit('addItem', { type: result.type, data: result })
      return result
    },
    updateItem ({ commit }, { id, body, type }: { id: number, body: any, type: string }) {
      return new Promise<ItemModel>((resolve, reject) => {
        Vue.axios
          .patch(`items/${id}`, body)
          .then(result => {
            commit('updateItem', { data: result.data, type: type })
            resolve(result.data)
          })
      })
    },
    updateItemType ({ commit }, { id, newItemType, type }: { id: number, newItemType: string, type: string }) {
      return new Promise<ItemModel>((resolve, reject) => {
        Vue.axios
          .patch(`items/${id}`, { type: newItemType })
          .then(result => {
            commit('deleteItem', { type: type, data: result.data })
            commit('addItem', { type: newItemType, data: result.data })
            resolve(result.data)
          })
      })
    },
    deleteItem ({ commit }, payload) {
      return new Promise<void>((resolve, reject) => {
        Vue.axios
          .delete(`items/${payload.data.id}`)
          .then(result => {
            commit('deleteItem', { type: payload.type, data: payload.data })
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
export default items
