export default
class ConstructionModel {
  id = -1;
  minimalValue = 0;
  maximalValue = 0;
  stringValue = '';
  refId = -1;
  specificationType = 'NUMERIC';
  items = [];
}
