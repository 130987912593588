<template>
  <v-layout column class="pb-10">
    <v-data-table :items="units" :headers="headers">
      <template v-slot:item.actions="{ item }">
        <v-btn @click="edit(item)" icon><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn :disabled="item.numUsages > 0" @click="del(item.id)" icon><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-btn absolute fab left style="bottom: 20px" bottom @click="add"><v-icon>mdi-plus</v-icon></v-btn>
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title>Einheit {{ addModel.id === -1 ? 'hinzufügen' : 'bearbeiten' }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="addModel.description" label="Beschreibung"></v-text-field>
          <v-text-field v-model="addModel.unit" label="Einheit (kurz)"></v-text-field>
          <v-text-field v-model="addModel.short" label="Abkürzung"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="addModel.id === -1" text @click="save">Hinzufügen</v-btn>
          <v-btn v-else text @click="save">Aktualisieren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script lang="js">
import DescriptionModel from '@/models/DescriptionModel'
import { Bus } from '@/main'
import UnitModel from '@/models/UnitModel'

export default {
  name: 'DictionaryView',
  created () {
    this.$store.dispatch('units/load')
  },
  data: () => ({
    tab: 0,
    dialog: false,
    addModel: new UnitModel(),
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Beschreibung', value: 'description' },
      { text: 'Einheit', value: 'unit' },
      { text: 'Abkürzung', value: 'short' },
      { text: 'Aktionen', value: 'actions' }
    ]
  }),
  methods: {
    save () {
      if (this.addModel.id === -1) {
        this.$store.dispatch('units/add', {
          description: this.addModel.description,
          unit: this.addModel.unit,
          short: this.addModel.short
        }).then((i) => {
          Bus.$emit('show-snackbar', `Einheit ${i.description} wurde hinzugefügt`)
          this.dialog = false
        }).catch((e) => {
          Bus.$emit('show-snackbar', `Einheit ${this.addModel.description} konnte nicht hinzugefügt werden, existiert sie schon?`)
          this.dialog = false
        })
      } else {
        this.$store.dispatch('units/update', {
          id: this.addModel.id,
          payload: {
            description: this.addModel.description,
            unit: this.addModel.unit,
            short: this.addModel.short
          }
        }).then((i) => {
          Bus.$emit('show-snackbar', 'Einheit aktualisiert')
          this.dialog = false
        }).catch((e) => {
          Bus.$emit('show-snackbar', `Einheit ${this.addModel.description} konnte nicht aktualisiert werden, existiert sie schon?`)
          this.dialog = false
        })
      }
    },
    del (id) {
      this.$store.dispatch('units/del', id).catch((i) => {
        console.warn(i)
        Bus.$emit('show-snackbar', 'Die Einheit konnte nicht gelöscht werden, besitzt sie noch Referenzen?')
      })
    },
    edit (item) {
      this.addModel.id = item.id
      this.addModel.description = item.description
      this.addModel.unit = item.unit
      this.addModel.short = item.short
      this.dialog = true
    },
    add () {
      this.addModel = new UnitModel()
      this.dialog = true
    }
  },
  computed: {
    units () {
      return this.$store.state.units.units
    }
  }
}
</script>

<style scoped>

</style>
