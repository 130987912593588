import { ServiceDataPostRequest, ServiceDataPutRequest, ServiceExpensesService, RoleItemPercentageService, RoleItemPercentagePostRequest, RoleItemPercentagePutRequest, CorrectionFactorsService, CorrectionFactor, CorrectionFactorPostRequest, CorrectionFactorPutRequest } from '@/__generated__'
const getAllDatapointServiceCalculations = () => ServiceExpensesService.getAllDatapointServiceCalculations()
const addDatapointServiceCalculation = (data: ServiceDataPostRequest) => ServiceExpensesService.addDatapointServiceCalculation({ requestBody: data })
const deleteDatapointServiceCalculation = (id: number) => ServiceExpensesService.deleteDatapointServiceCalculation({ id })
const overwriteDatapointConfiguration = (data: ServiceDataPutRequest, id: number) => ServiceExpensesService.overwriteDatapointConfiguration({ requestBody: data, id })
const getRoleItemPercentages = () => RoleItemPercentageService.getRoleItemPercentages()
const addRoleItemPercentage = (request: RoleItemPercentagePostRequest) => RoleItemPercentageService.addRoleItemPercentage({ requestBody: request })
const changeRoleItemPercentage = (id: number, request: RoleItemPercentagePutRequest) => RoleItemPercentageService.changeRoleItemPercentage({ id, requestBody: request })
const deleteRoleItemPercentage = (id: number) => RoleItemPercentageService.deleteRoleItemPercentage({ id })
const getAllCorrectionFactors = () => CorrectionFactorsService.getAllCorrectionFactors()
const addCorrectionFactor = (request: CorrectionFactorPostRequest) => CorrectionFactorsService.addCorrectionFactor({ requestBody: request })
const changeCorrectionFactor = (id: CorrectionFactorPostRequest.id, shared: boolean, request: CorrectionFactorPutRequest) => CorrectionFactorsService.changeCorrectionFactor({ id, requestBody: request, shared })
export { getAllDatapointServiceCalculations, addDatapointServiceCalculation, deleteDatapointServiceCalculation, overwriteDatapointConfiguration, getRoleItemPercentages, addRoleItemPercentage, changeRoleItemPercentage, deleteRoleItemPercentage, getAllCorrectionFactors, changeCorrectionFactor, addCorrectionFactor }
