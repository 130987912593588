
import Vue from 'vue'
import { ApiError, FunctionList, NamedFunctionList } from '@/__generated__'
import { Bus } from '@/main'
import items from '@/store/items'

export default Vue.extend({
  name: 'FunctionListVue',
  data: () => ({
    leftHeaders: ['actions', 'BGR', 'functionListId'],
    page: 0,
    pageCount: 0,
    itemsPerPage: 50,
    addFunctionListDialog: {
      value: false,
      name: '',
      id: undefined as number | undefined,
      isValid: false
    },
    deleteDialog: {
      value: false,
      id: -1
    },
    requiredRule: (value: string | undefined) => (value !== undefined && value.length > 0) || 'Dieses Feld darf nicht leer sein.',
    numberRule: (value: number | undefined) => Number.isInteger(value) || 'Zahl',
    editingId: -1,
    functionPoints: ['BA', 'AA', 'BEM', 'BEZ', 'AE', 'GBA', 'GAA', 'GBEM', 'GBEZ', 'GAE', 'GWF', 'GWG', 'BSTERF', 'ERZ', 'BAK', 'MB', 'AST', 'MST', 'UM', 'FS', 'FROST', 'P', 'PID', 'SWF', 'STY', 'ST2P', 'STPWM', 'BEG', 'PUM', 'HX', 'ABEG', 'ERS', 'ZEITS', 'GLEA', 'ZYKL', 'NACHT', 'RABEG', 'ERG', 'NEB', 'NWP', 'HLBEG', 'TARIF', 'OBTYP', 'KOMPL', 'LANG', 'HIST', 'GRAFIK', 'DYNEIN', 'TEXT', 'NEXT']
  }),
  computed: {
    items () {
      return items
    },
    functionList () {
      return this.$store.state.functionList.functionLists
    },
    functionListIdsSliced () {
      const array = this.$store.state.functionList.functionLists.map((i: FunctionList) => i.functionListId) as number[]
      const chunks = [[]] as [number[]]
      for (let i = 0; i < array.length; i += this.itemsPerPage) {
        const chunk = array.slice(i, i + this.itemsPerPage)
        // do whatever
        chunks.push(chunk)
      }
      return chunks
    },
    computedSimpleHeaders () {
      return this.leftHeaders.map((i: string) => {
        return {
          value: i === 'functionListId' ? i : i.toLowerCase(),
          text: i
        }
      })
    },
    computedHeaders () {
      return this.functionPoints.map((i: string) => {
        return {
          value: i === 'functionListId' ? i : i.toLowerCase(),
          text: i
        }
      })
    },
    leftScrollableElement (): HTMLElement | null {
      return this.findScrollableElement('leftTableHeaderRef')
    },
    rightScrollableElement (): HTMLElement | null {
      return this.findScrollableElement('rightTableHeaderRef')
    }
  },
  mounted () {
    this.addScrollSync()
  },
  beforeDestroy () {
    this.removeScrollSync()
  },
  methods: {
    openDeleteDialog (id: number) {
      this.deleteDialog.value = true
      this.deleteDialog.id = id
    },
    async confirmDeleteItem () {
      if (this.deleteDialog.id === -1) {
        return
      }
      const deletedId = await this.$store.dispatch('functionList/deleteFunctionList', this.deleteDialog.id)
      if (deletedId === null) {
        Bus.$emit('show-snackbar', 'Die Funktionsliste konnte leider nicht gelöscht werden. Bestehen noch Referenzen?')
      } else {
        Bus.$emit('show-snackbar', 'Die Funktionsliste mit id ' + deletedId + ' wurde gelöscht')
      }
      this.deleteDialog.value = false
    },
    async saveNewFunctionList () {
      try {
        const a = await this.$store.dispatch('functionList/addNewFunctionList', { functionListId: this.addFunctionListDialog.id, bgr: this.addFunctionListDialog.name })
        this.addFunctionListDialog.value = false
        Bus.$emit('show-snackbar', `Funktionsliste ${a.bgr} mit ID ${a.functionListId} wurde erstellt.`)
      } catch (e) {
        if (e instanceof ApiError) {
          if (e.status === 409) {
            Bus.$emit('show-snackbar', 'Funktionsliste mit id ' + this.addFunctionListDialog.id + ' existiert bereits.')
          }
        }
      }
    },
    async openAddDialog () {
      this.addFunctionListDialog.value = true
      this.addFunctionListDialog.name = ''
      this.addFunctionListDialog.id = undefined
      const a = await this.$store.dispatch('functionList/getNextFunctionListId')
      this.addFunctionListDialog.id = a
    },
    beginEditItem (id: number) {
      this.editingId = id
    },
    endEditItem (id: number) {
      this.editingId = -1
    },
    async updateFunctionListValue (id: number, header: string, value: string | undefined) {
      let intValue = null as number | null
      if (value !== undefined) {
        const parsedInt = parseInt(value)
        if (!isNaN(parsedInt)) {
          intValue = parsedInt
        }
      }
      const request = { [header]: intValue }
      await this.$store.dispatch('functionList/updateFunctionList', { id, request })
      Bus.$emit('show-snackbar', `Funktionslistenfeld ${header} wurde auf ${intValue ?? '-'} gesetzt.`)
    },
    async updateFunctionListValueAsString (id: number, header: string, value: string | undefined) {
      const request = { [header]: value }
      await this.$store.dispatch('functionList/updateFunctionList', { id, request })
      Bus.$emit('show-snackbar', `Funktionslistenfeld ${header} wurde auf ${value ?? '-'} gesetzt.`)
    },
    findScrollableElement (refName: string): HTMLElement | null {
      const tableRef = this.$refs[refName] as Vue | undefined
      return tableRef ? tableRef.$el.querySelector('.v-data-table__wrapper') as HTMLElement | null : null
    },
    addScrollSync (): void {
      const scrollableElement1 = this.findScrollableElement('leftTableHeaderRef')
      const scrollableElement2 = this.findScrollableElement('rightTableHeaderRef')

      if (scrollableElement1) {
        scrollableElement1.addEventListener('scroll', this.handleScroll)
      }
      if (scrollableElement2) {
        scrollableElement2.addEventListener('scroll', this.handleScroll)
      }
    },
    removeScrollSync (): void {
      const scrollableElement1 = this.findScrollableElement('leftTableHeaderRef')
      const scrollableElement2 = this.findScrollableElement('rightTableHeaderRef')

      if (scrollableElement1) {
        scrollableElement1.removeEventListener('scroll', this.handleScroll)
      }
      if (scrollableElement2) {
        scrollableElement2.removeEventListener('scroll', this.handleScroll)
      }
    },
    handleScroll (event: Event): void {
      const target = event.target as HTMLElement
      const otherElement = target === this.leftScrollableElement ? this.rightScrollableElement : this.leftScrollableElement

      if (otherElement) {
        otherElement.scrollTop = target.scrollTop
      }
    }
  }
})
