<template>
  <v-container>
    <div class="text-h3">
      Wörterbuch
    </div>
    <v-tabs v-model="tab">
      <v-tab>Relationsbeschreibungen</v-tab>
      <v-tab>Einheiten</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <relation-description-component />
      </v-tab-item>
      <v-tab-item>
        <unit-component />/descriptors
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import DescriptionModel from '@/models/DescriptionModel'
import RelationDescriptionComponent from '@/components/RelationDescriptionComponent'
import UnitComponent from '@/components/UnitComponent'
import { Bus } from '@/main'

export default {
  name: 'DictionaryView',
  components: {
    RelationDescriptionComponent, UnitComponent
  },
  data: () => ({
    tab: 0
  })
}
</script>

<style scoped>

</style>
