import IRelationController from '@/controllers/IRelationController'
import PlantConstructionRelationModel from '@/models/PlantConstructionRelationModel'
import Store from '@/store/index.ts'
import RelationFactory from '@/controllers/RelationFactory'
import ConstructionModel from '@/models/ConstructionModel'
import ConnectionRequest from '@/models/ConnectionRequest'
import StripConstructionRelationModel from '@/models/StripConstructionRelationModel'

class StripConstructionFactory implements RelationFactory<StripConstructionRelationModel> {
  create (): StripConstructionRelationModel {
    return new StripConstructionRelationModel()
  }
}

export default class StripConstructionRelationController extends IRelationController<StripConstructionRelationModel, ConstructionModel> {
  title = 'Komponenten'
  addItemToStoreReference = 'addStripConstruction'
  deleteItemFromStoreReference = 'deleteStripConstruction'
  loadFromStoreReference = 'loadStripConstructions'
  modifyItemInStoreReference = 'changeStripConstruction'
  headers = [
    { text: 'Sortierung', value: 'orderBy', sortable: true },
    { text: 'ID (generiert)', value: 'id', width: '5%', sortable: true },
    { text: 'Name', width: '40%', value: 'construction', sortable: false },
    { text: 'Bezeichner', width: '40%', value: 'description', sortable: false },
    { text: 'Aktionen', value: 'actions', sortable: false }
  ]

  prefetchDataReference = 'loadConstructions'

  names (): Array<ConstructionModel> {
    return Store.state.constructions
  }

  factory = new StripConstructionFactory()

  toConnectionRequest (item: StripConstructionRelationModel): ConnectionRequest {
    return new ConnectionRequest(
      item.construction.id,
      item.description.id,
      item.orderBy,
      item.id
    )
  }
}
