import Vue from 'vue'
import { Module } from 'vuex'
import DescriptionModel from '@/models/DescriptionModel'

const dictionary: Module<any, any> = {
  namespaced: true,
  state: () => ({
    loading: true,
    relationDescriptions: Array<DescriptionModel>()
  }),
  mutations: {
    setRelationDescriptions (state, relationDescriptions: []) {
      state.relationDescriptions = relationDescriptions
    },
    setLoading (state, loading: boolean) {
      state.loading = loading
    },
    addRelationDescription (state, relationDescription: DescriptionModel) {
      state.relationDescriptions.push(relationDescription)
    },
    updateRelationDescription (state, relationDescription: DescriptionModel) {
      const i = state.relationDescriptions.findIndex((i: DescriptionModel) => i.id === relationDescription.id)
      state.relationDescriptions[i].description = relationDescription.description
    },
    deleteRelationDescription (state, id: number) {
      state.relationDescriptions.splice(state.relationDescriptions.findIndex((a: DescriptionModel) => a.id === id), 1)
    }
  },
  actions: {
    loadDescriptions ({ commit }) {
      commit('setLoading', true)
      Vue.axios
        .get('macrolib/descriptions')
        .then((result) => {
          commit('setLoading', false)
          commit('setRelationDescriptions', result.data)
        })
    },
    addDescription ({ commit }, body) {
      return new Promise<DescriptionModel>((resolve, reject) => {
        Vue.axios
          .post('macrolib/descriptions', body)
          .then((result) => {
            commit('addRelationDescription', result.data)
            resolve(result.data)
          }).catch((e) => {
            reject(e)
          })
      })
    },
    updateDescription ({ commit }, payload) {
      return new Promise<DescriptionModel>((resolve, reject) => {
        Vue.axios
          .patch(`macrolib/descriptions/${payload.id}`, payload.body)
          .then((result) => {
            commit('updateRelationDescription', result.data)
            resolve(result.data)
          }).catch((e) => {
            reject(e)
          })
      })
    },
    deleteDescription ({ commit }, id) {
      return new Promise<void>((resolve) => {
        Vue.axios
          .delete(`macrolib/descriptions/${id}`)
          .then(() => {
            commit('deleteRelationDescription', id)
            resolve()
          })
      })
    }
  }
}
export default dictionary
