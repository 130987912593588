import Vue from 'vue'
import axios from 'axios'
import { Module } from 'vuex'
import SpecificationModel from '@/models/SpecificationModel'
import UnitModel from '@/models/UnitModel'

const units: Module<any, any> = {
  namespaced: true,
  state: () => ({
    units: Array<UnitModel>()
  }),
  mutations: {
    setUnits (state, units: UnitModel[]) {
      state.units = units
    },
    addUnit (state, unit: UnitModel) {
      state.units.push(unit)
    },
    deleteUnit (state, id: number) {
      state.units = state.units.filter((i: UnitModel) => i.id !== id)
    },
    editUnit (state, unit: UnitModel) {
      const index = state.units.findIndex((i: UnitModel) => {
        return i.id === unit.id
      })
      state.units[index].short = unit.short
      state.units[index].description = unit.description
      state.units[index].unit = unit.unit
    }
  },
  actions: {
    load ({ commit }) {
      Vue.axios
        .get('macrolib/units')
        .then((result) => {
          commit('setUnits', result.data)
        })
    },
    add ({ commit }, payload) {
      return new Promise<UnitModel>((resolve) => {
        Vue.axios
          .post('macrolib/units', payload)
          .then((result) => {
            commit('addUnit', result.data)
            resolve(result.data)
          })
      })
    },
    update ({ commit }, { id, payload }) {
      return new Promise<UnitModel>((resolve) => {
        Vue.axios
          .patch(`macrolib/units/${id}`, payload)
          .then((result) => {
            commit('editUnit', result.data)
            resolve(result.data)
          })
      })
    },
    del ({ commit }, id) {
      return new Promise<void>((resolve, reject) => {
        Vue.axios
          .delete(`macrolib/units/${id}`)
          .then((result) => {
            commit('deleteUnit', id)
          }).catch((e) => {
            reject(e)
          })
      })
    }
  }
}
export default units
