export class UserModel {
  id = -1;
  firstName = '';
  lastName = '';
}
export class DetailUserModel extends UserModel {
  id = -1;
  firstName = '';
  lastName = '';
  profession = '';
  isAdmin = false;
  tags = [];
}
