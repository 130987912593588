
import Vue from 'vue'
import { Setting } from '@/__generated__/models/Setting'
const regex = /^[A-Z_]+$/
export default Vue.extend({
  data: function () {
    return {
      dialog: {
        value: false,
        data: {
          settingName: '',
          type: Setting.settingType.STRING,
          value: ''
        }
      },
      values: [
        {
          value: Setting.settingType.STRING,
          text: 'Zeichenkette (String)'
        },
        {
          value: Setting.settingType.FLOAT,
          text: 'Gleitkommazahl (Float)'
        },
        {
          value: Setting.settingType.INT,
          text: 'Ganzzahl (Integer)'
        },
        {
          value: Setting.settingType.LONG,
          text: 'Langzahl (Long)'
        }
      ],
      headers: [
        {
          text: 'Einstellungsname',
          value: 'settingName'
        },
        {
          text: 'Datentyp',
          value: 'settingType'
        },
        {
          text: 'Wert',
          value: 'settingValue',
          width: '200px'
        },
        {
          text: 'Aktionen',
          value: 'actions'
        }
      ],
      onlyCapitals: (value: string | undefined) => (value !== undefined && regex.test(value)) || 'Format: VARIABLE_NAME.'
    }
  },
  computed: {
    settings () {
      return this.$store.state.settings.settings
    }
  },
  mounted () {
    this.$store.dispatch('settings/getSettings')
  },
  methods: {
    openDialog () {
      this.dialog.value = true
    },
    async save () {
      await this.$store.dispatch('settings/addSetting', { name: this.dialog.data.settingName, type: this.dialog.data.type, value: this.dialog.data.value })
      this.dialog.value = false
    },
    async update (name: string, value: string) {
      await this.$store.dispatch('settings/changeSetting', { name, value })
    },
    async deleteSetting (name: string) {
      await this.$store.dispatch('settings/deleteSetting', name)
    }
  }
})
