import { addRole, deleteRole, findAllRoles, replaceRole } from '@/api/roles'
import { ProjectRole, ProjectRolePostRequest, ProjectRolePutRequest } from '@/__generated__'
import { Module } from 'vuex'

const roles: Module<any, any> = {
  namespaced: true,
  mutations: {
    setRoles (state, roles: ProjectRole[]) {
      state.roles = roles
    },
    addRole (state, role: ProjectRole) {
      state.roles = [...state.roles, role]
    },
    changeRole (state, role: ProjectRole) {
      const index = state.roles.findIndex((i: ProjectRole) => i.id === role.id)
      state.roles[index] = role
      state.roles = [...state.roles]
    },
    deleteRole (state, id: string) {
      state.roles = state.roles.filter((i: ProjectRole) => i.id !== id)
    }
  },
  state: {
    roles: [] as ProjectRole[]
  },
  actions: {
    async getRoles ({ commit }) {
      const a = await findAllRoles()
      commit('setRoles', a)
    },
    async addRole ({ commit }, request: ProjectRolePostRequest) {
      const a = await addRole(request)
      commit('addRole', a)
    },
    async updateRole ({ commit }, { id, request }: { id: string, request: ProjectRolePutRequest }) {
      const a = await replaceRole(id, request)
      commit('changeRole', a)
    },
    async deleteRole ({ commit }, id: string) {
      await deleteRole(id)
      commit('deleteRole', id)
    }
  }
}
export { roles }
