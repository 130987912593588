import ConstructionModel from '@/models/ConstructionModel'
import DescriptionModel from '@/models/DescriptionModel'
import RelationInterface from '@/models/RelationInterface'

export default class PlantConstructionRelationModel implements RelationInterface {
  id= -1;
  orderBy=0;
  construction = new ConstructionModel();
  description = new DescriptionModel();
}
