<template>
  <v-layout>
    <v-dialog
      v-model="dialog"
      fullscreen
      scrollable
      @keydown.right="nextItem"
      @keydown.left="previousItem"
    >
      <v-card>
        <v-card-title>
          <v-btn
            :disabled="loading"
            icon
            @click="previousItem"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="flex-grow-1">
            <v-row v-if="isAdmin">
              <v-col cols="6">
                <v-text-field v-model="construction.name" />
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="construction.unit"
                  clearable
                  :label="$t('dialog.unit')"
                  item-text="unit"
                  item-value="unit"
                  :items="units"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.unit }} ({{ item.description }})
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.unit }} ({{ item.description }})
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-btn
                  class="mt-3"
                  icon
                  small
                  @click="updateConstruction"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div v-else>
              <!-- TODO:ASK-->
              {{ construction.name }} ({{ construction.unit ? construction.unit : 'ohne Einheit' }})
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            :disabled="loading"
            icon
            @click="nextItem"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn
            :disabled="loading"
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-overline">
            Spezifikationen <v-btn
              v-if="isAdmin"
              icon
              color="pink"
              text
              @click="editSpecification(-1)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-skeleton-loader
            transition="fade-transition"
            type="table-thead"
            :loading="loading"
          >
            <v-data-table
              :items="specifications"
              :headers="headers"
            >
              <template v-slot:item.meta="{ item }">
                <v-btn
                  v-if="isAdmin"
                  small
                  icon
                  @click="deleteSpecification(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="isAdmin"
                  small
                  icon
                  @click="editSpecification(item.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <span class="text-caption grey--text">ID: {{ item.id }}</span>
              </template>
              <template
                v-slot:item.name="{ item }"
                type="table"
              >
                <div :class="item.default ? 'font-weight-bold' : ''">
                  {{ item.name }}<v-badge
                    v-if="item.default"
                    color="pink"
                    dot
                    offset-y="-10"
                  />
                </div>
              </template>
              <template v-slot:item.items="{ item }">
                <v-btn
                  v-if="isAdmin"
                  icon
                  x-small
                  @click="addItem(item, 0)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-chip-group column>
                  <v-chip
                    small
                    v-for="i in item.items"
                    text-color="white"
                    :key="`fd-${i.id}`"
                    :close="isAdmin"
                    color="orange"
                    @click:close="disconnect(item, i.id)"
                  >
                    <v-avatar
                      class="custom-avatar"
                      left
                      color="orange lighten-3"
                    >
                      {{ item.itemQuantity[i.name] }}
                    </v-avatar>
                    {{ i.name }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:item.cabinets="{ item }">
                <v-btn
                  v-if="isAdmin"
                  icon
                  x-small
                  @click="addItem(item, 1)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-chip-group column>
                  <v-chip
                    v-for="i in item.cabinets"
                    :key="`cb-${i.id}`"
                    small
                    class="mr-1"
                    text-color="white"
                    :close="isAdmin"
                    color="blue"
                    @click:close="disconnect(item, i.id)"
                  >
                    <v-avatar
                      class="custom-avatar"
                      left
                      color="blue lighten-3"
                    >
                      {{ item.itemQuantity[i.name] }}
                    </v-avatar>
                    {{ i.name }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:item.cables="{ item }">
                <v-btn
                  v-if="isAdmin"
                  icon
                  x-small
                  @click="addItem(item, 2)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-chip-group column>
                  <v-chip
                    v-for="i in item.cables"
                    :key="`cable-${i.id}`"
                    small
                    :close="isAdmin"
                    class="mr-1"
                    text-color="white"
                    color="green"
                    @click:close="disconnect(item, i.id)"
                  >
                    <v-avatar
                      class="custom-avatar"
                      left
                      color="green lighten-3"
                    >
                      {{ item.itemQuantity[i.name] }}
                    </v-avatar>
                    {{ i.description }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:item.refId="{ item }">
                <v-flex class="flex-column">
                  <v-autocomplete
                    v-model="item.refId"
                    auto-select-first
                    :items="$store.state.functionList.functionListsIds"
                    item-value="functionListId"
                    item-text="functionListId"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.functionListId }} ({{ item.bgr }})
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.functionListId }} ({{ item.bgr }})
                    </template>
                    <template v-slot:append-outer>
                      <v-btn
                        icon
                        @click="updateSpec(item.id, { functionList: item.refId })"
                      >
                        <v-icon>mdi-upload</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </template>
            </v-data-table>
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="addDialog"
        width="400px"
      >
        <v-card>
          <v-card-title>
            <v-toolbar-title>{{ $t('view.connect', { types: types[typeIndex] }) }}</v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <div class="text-overline">
              {{ $t('view.connected') }}
            </div>
            <v-chip
              v-for="s in myCurrentItems"
              :key="`${s.id}-spec-del`"
              close
              @click="selectSpec(s)"
              @click:close="disconnectItem(s.id)"
            >
              {{ s.name }}
            </v-chip>
            <div class="text-overline">
              {{ $t('view.newConnections') }}
            </div>
            <v-layout>
              <v-autocomplete
                v-model="selectedItem"
                class="mr-2"
                item-value="id"
                :item-text="typeIndex === 2 ? 'description' : 'name'"
                :items="myItems"
                :label="$t('view.ERPNumber')"
                :hint="$t('view.searchERPNumberInSystem')"
                append-icon="mdi-magnify"
              />
              <v-select
                v-model="quantity"
                :items="itemQuantityRange"
                style="width: 100px"
                label="Anzahl (opt.)"
              />
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="green"
              @click="connectItem"
            >
              {{ $t('view.connecting') }}
            </v-btn>
            <v-btn
              text
              color="warning"
              @click="connectForAll"
            >
              {{ $t('view.connectAll') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ message }}
    </v-snackbar>
    <specification-dialog />
  </v-layout>
</template>
<script>
import Vue from 'vue'
import { Bus } from '@/main'
import PlantModel from '@/models/PlantModel'
import SpecificationDialog from '@/components/SpecificationDialog'
export default Vue.extend({
  name: 'ConstructionDetailViewVue',
  components: { SpecificationDialog },
  data: () => ({
    // Edit Mode changes Specifications, if false, specifications are added
    loading: false,
    selectedItem: null,
    types: ['Feldgerät', 'Schaltschrank', 'Kabel'],
    typeIndex: 0,
    quantity: null,
    currentId: -1,
    dialog: false,
    message: 'Snackbar',
    snackbar: false,
    addDialog: false,
    referenceId: -1,
    headers: [
      { text: 'Meta', value: 'meta' },
      { text: 'Beschreibung', value: 'name' },
      { text: 'Postfix', value: 'postfix' },
      { text: 'Feldgeräte', value: 'items' },
      { text: 'Schaltschrankartikel', value: 'cabinets' },
      { text: 'Kabel', value: 'cables' },
      { text: 'REF-ID', value: 'refId' }
    ]
  }),
  computed: {
    itemQuantityRange () {
      return Array.from({ length: 100 }, (_, i) => i + 1)
    },
    units () {
      return this.$store.state.units.units
    },
    reference () {
      if (this.referenceId === -1) {
        return []
      }
      return this.specifications[this.referenceId]
    },
    myItems () {
      switch (this.typeIndex) {
        case 0:
          return this.fieldDevices
        case 1:
          return this.$store.state.items.cabinets
        case 2:
          return this.$store.state.items.cables
      }
      return []
    },
    fieldDevices () {
      if (this.$store.state.items === undefined) {
        return []
      }
      return this.$store.state.items.fielddevices
    },
    myCurrentItems () {
      if (this.reference === undefined) {
        return []
      }
      switch (this.typeIndex) {
        case 0:
          console.log(this.reference)
          return this.reference.items
        case 1:
          return this.reference.cabinets
        case 2:
          return this.reference.cables
      }
      return []
    },
    construction () {
      return this.$store.state.constructionDetail.construction
    },
    specifications () {
      return this.construction.specifications
    },
    isAdmin () {
      return this.$store.state.currentUser.isAdmin
    }
  },
  created () {
    this.$store.dispatch('items/loadFieldDevices')
    this.$store.dispatch('items/loadCabinets')
    this.$store.dispatch('items/loadCables')

    Bus.$on('open-construction-detail', (id) => {
      this.dialog = true
      this.loading = true
      this.loadItem(id)
    })
  },
  methods: {
    selectSpec (item) {
      this.selectedItem = item.id
    },
    updateSpec (id, object) {
      console.log(object)
      this.$store.dispatch('specificationDetail/patchSpecification', {
        id: id,
        object: object
      }).then(() => {
        Bus.$emit('show-snackbar', 'REF-ID wurde aktualisiert')
      })
    },
    updateConstruction () {
      const unit = this.units.find((i) => {
        return i.unit === this.construction.unit
      })
      this.$store.dispatch('constructionDetail/updateAttribute', {
        id: this.construction.id,
        data: {
          name: this.construction.name,
          unitId: unit ? unit.id : null
        }
      }).then(() => {
        this.$store.dispatch('loadConstructions')
      })
    },
    deleteSpecification (id) {
      this.$store.dispatch('specificationDetail/deleteSpecification', { id: id }).then(() => {
        this.$store.dispatch('constructionDetail/update')
      })
    },
    editSpecification (id) {
      Bus.$emit('open-specification-dialog', id)
    },
    prompt (s) {
      this.message = s
      this.snackbar = true
    },
    disconnect (item, id) {
      this.referenceId = this.specifications.indexOf(item)
      this.selectedItem = null
      this.disconnectItem(id)
    },
    addItem (item, type) {
      this.quantity = null
      this.referenceId = this.specifications.indexOf(item)
      this.selectedItem = null
      this.addDialog = true
      this.typeIndex = type
    },
    connectForAll () {
      const length = this.specifications.length
      let l = 0
      this.specifications.forEach((s) => {
        this.$store.dispatch('constructionDetail/addItemToSpecification', {
          specId: s.id,
          itemId: this.selectedItem
        }).then(() => {
          l++
          if (length === l) {
            this.loadItem(this.currentId)
            this.addDialog = false
            this.prompt(`Massenbearbeitung für ${l} Spezifikation(en) erfolgreich`)
          }
        })
      })
    },
    disconnectItem (id) {
      console.log(this.referenceId)
      this.$store.dispatch('constructionDetail/removeItemFromSpecification', {
        specId: this.reference.id,
        itemId: id
      }).then(() => {
        this.loadItem(this.currentId)
        this.prompt('Erfolgreich getrennt')
      })
    },
    connectItem (quantity) {
      this.$store.dispatch('constructionDetail/addItemToSpecification', {
        specId: this.reference.id,
        itemId: this.selectedItem,
        quantity: this.quantity
      }).then(() => {
        this.loadItem(this.currentId)
        this.addDialog = false
        this.prompt('Verknüpfung erfolgreich')
      })
    },
    isDefault (i) {
      console.log(i)
      return i.default
    },
    formatItems (i) {
      if (i === undefined) {
        return ''
      }
      return i.map(x => x.name).join(', ')
    },
    loadItem (id) {
      this.loading = true
      this.$store.dispatch('constructionDetail/loadConstruction', id).then(() => {
        this.loading = false
        this.currentId = id
      })
    },
    nextItem () {
      if (this.loading) {
        return
      }
      this.referenceId = 0
      const nextIndex = this.$store.state.constructions.findIndex((item) => {
        return item.id === this.construction.id
      })
      if (nextIndex + 1 >= this.$store.state.constructions.length) { return }
      this.loadItem(this.$store.state.constructions[nextIndex + 1].id)
    },
    previousItem () {
      if (this.loading) {
        return
      }
      this.referenceId = 0
      const ind = this.$store.state.constructions.findIndex((item) => {
        return item.id === this.construction.id
      })
      if (ind <= 0) { return }
      this.loadItem(this.$store.state.constructions[ind - 1].id)
    }
  }
})
</script>

<style scoped>
.v-avatar.custom-avatar {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}
</style>
