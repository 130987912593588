/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServiceDataPostRequest } from '../models/ServiceDataPostRequest';
import type { ServiceDataPutRequest } from '../models/ServiceDataPutRequest';
import type { ServiceEntryDTO } from '../models/ServiceEntryDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceExpensesService {

  /**
   * Dienstleistungsberechnung nach ID abrufen
   * Holt eine spezifische Dienstleistungsberechnung anhand ihrer ID.
   * @returns ServiceEntryDTO OK
   * @throws ApiError
   */
  public static getDatapointServiceCalculationById({
    id,
  }: {
    /**
     * Die ID der Dienstleistungsberechnung.
     */
    id: number,
  }): CancelablePromise<ServiceEntryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Dienstleistungsberechnung überschreiben
   * Überschreibt eine bestehende Dienstleistungsberechnung mit neuen Daten.
   * @returns ServiceEntryDTO OK
   * @throws ApiError
   */
  public static overwriteDatapointConfiguration({
    id,
    requestBody,
    data,
  }: {
    /**
     * Die ID der zu überschreibenden Dienstleistungsberechnung.
     */
    id: number,
    requestBody: ServiceDataPutRequest,
    /**
     * Die neuen Daten für die Dienstleistungsberechnung.
     */
    data?: any,
  }): CancelablePromise<ServiceEntryDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/services/{id}',
      path: {
        'id': id,
      },
      query: {
        'data': data,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Dienstleistungsberechnungskonfiguration löschen
   * Löscht eine spezifische Dienstleistungsberechnung anhand ihrer ID.
   * @returns string OK
   * @throws ApiError
   */
  public static deleteDatapointServiceCalculation({
    id,
  }: {
    /**
     * Die ID der zu löschenden Dienstleistungsberechnung.
     */
    id: number,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/services/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Alle Dienstleistungsberechnungen abrufen
   * Holt alle Dienstleistungsberechnungen, die im System gespeichert sind.
   * @returns ServiceEntryDTO OK
   * @throws ApiError
   */
  public static getAllDatapointServiceCalculations(): CancelablePromise<Array<ServiceEntryDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services',
    });
  }

  /**
   * Neue Dienstleistungsberechnungskonfiguration hinzufügen
   * Fügt eine neue Dienstleistungsberechnung hinzu.
   * @returns ServiceEntryDTO OK
   * @throws ApiError
   */
  public static addDatapointServiceCalculation({
    requestBody,
    data,
  }: {
    requestBody: ServiceDataPostRequest,
    /**
     * Die Anforderungsdaten für die neue Dienstleistungsberechnung, bestehend aus Rolle, Datenpunkttyp, Sekunden und Artikel
     */
    data?: any,
  }): CancelablePromise<ServiceEntryDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/services',
      query: {
        'data': data,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
