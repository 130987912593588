
import { CorrectionFactor } from '@/__generated__'
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    dialog: {
      value: false,
      type: CorrectionFactor.id.ANALOG_INPUT,
      shared: false,
      multiplier: 0,
      isEditing: false,
      summand: 0
    },
    headers: [
      {
        text: 'Beschreibung',
        value: 'text'
      },
      {
        text: 'Geteilt',
        value: 'shared'
      },
      {
        text: 'Summand (b)',
        value: 'summand'
      },
      {
        text: 'Multiplikator (m)',
        value: 'multiplier'
      },
      {
        text: 'Aktionen',
        value: 'actions'
      }
    ],
    physicalTypeToText: [
      {
        text: 'Analoger Eingang (physikalisch)',
        value: CorrectionFactor.id.ANALOG_INPUT
      },
      {
        text: 'Analoger Ausgang (physikalisch)',
        value: CorrectionFactor.id.ANALOG_OUTPUT
      },
      {
        text: 'Binärer Eingang melden (physikalisch)',
        value: CorrectionFactor.id.BINARY_INPUT
      },
      {
        text: 'Binärer Ausgang (physikalisch)',
        value: CorrectionFactor.id.BINARY_OUTPUT
      },
      {
        text: 'Binärer Eingang zählen (physikalisch)',
        value: CorrectionFactor.id.COUNTER_INPUT
      }
    ],
    sharedTypeToText: [
      {
        text: 'Analoger Eingang (kommunikativ)',
        value: CorrectionFactor.id.ANALOG_INPUT
      },
      {
        text: 'Analoger Ausgang (kommunikativ)',
        value: CorrectionFactor.id.ANALOG_OUTPUT
      },
      {
        text: 'Binärer Eingang melden (kommunikativ)',
        value: CorrectionFactor.id.BINARY_INPUT
      },
      {
        text: 'Binärer Ausgang (kommunikativ)',
        value: CorrectionFactor.id.BINARY_OUTPUT
      },
      {
        text: 'Binärer Eingang zählen (kommunikativ)',
        value: CorrectionFactor.id.COUNTER_INPUT
      }
    ],
    isNumber: (value: string | undefined) => (value !== undefined && !Number.isNaN(parseFloat(value))) || 'Muss eine Zahl sein'
  }),
  computed: {
    items () {
      return this.$store.state.services.correctionFactors.map((e: CorrectionFactor) => {
        const texts = e.shared ? this.sharedTypeToText : this.physicalTypeToText
        return {
          id: e.id,
          text: texts.find((f) => f.value === e.id)?.text,
          shared: e.shared,
          multiplier: e.multiplier,
          summand: e.summand
        }
      })
    }
  },
  mounted () {
    this.$store.dispatch('services/loadCorrectionFactors')
  },
  methods: {
    async save () {
      if (this.dialog.isEditing) {
        const request = {
          summand: this.dialog.summand,
          multiplier: this.dialog.multiplier
        }
        await this.$store.dispatch('services/changeCorrectionFactor', { id: this.dialog.type, shared: this.dialog.shared, request })
      } else {
        const request = {
          id: this.dialog.type,
          multiplier: this.dialog.multiplier,
          shared: this.dialog.shared,
          summand: this.dialog.summand
        }
        await this.$store.dispatch('services/addCorrectionFactor', request)
      }

      this.dialog.value = false
    },
    openEditDialog (id: CorrectionFactor.id, shared: boolean, multiplier: number, summand: number) {
      this.dialog.isEditing = true
      this.dialog.type = id
      this.dialog.shared = shared
      this.dialog.multiplier = multiplier
      this.dialog.summand = summand
      this.dialog.value = true
    },
    openAddDialog () {
      this.dialog.isEditing = false
      this.dialog.value = true
    }
  }
})
