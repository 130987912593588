import RelationInterface from '@/models/RelationInterface'
import DescriptionModel from '@/models/DescriptionModel'
import StripModel from '@/models/StripModel'

export default class PlantStripRelationModel implements RelationInterface {
  orderBy=0
  id= -1;
  strip = new StripModel()
  description = new DescriptionModel()
}
