/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleItemPercentageDTO } from '../models/RoleItemPercentageDTO';
import type { RoleItemPercentagePostRequest } from '../models/RoleItemPercentagePostRequest';
import type { RoleItemPercentagePutRequest } from '../models/RoleItemPercentagePutRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleItemPercentageService {

  /**
   * Rollen-Artikel-Prozentsatz ändern
   * Ändert einen spezifischen Rollen-Artikel-Prozentsatz basierend auf seiner ID.
   * @returns RoleItemPercentageDTO OK
   * @throws ApiError
   */
  public static changeRoleItemPercentage({
    id,
    requestBody,
    request,
  }: {
    /**
     * Die ID des zu ändernden Rollen-Item-Prozentsatzes.
     */
    id: number,
    requestBody: RoleItemPercentagePutRequest,
    /**
     * Die Anforderungsdaten für die Änderung.
     */
    request?: any,
  }): CancelablePromise<RoleItemPercentageDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/services/percentages/{id}',
      path: {
        'id': id,
      },
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Rollen-Artikel-Prozentsatz löschen
   * Löscht einen spezifischen Rollen-Artikel-Prozentsatz basierend auf seiner ID.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteRoleItemPercentage({
    id,
  }: {
    /**
     * Die ID des zu löschenden Rollen-Artikel-Prozentsatzes.
     */
    id: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/services/percentages/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Rollen-Artikel-Prozentsätze abrufen
   * Holt alle Rollen-Artikel-Prozentsätze, die in der Datenbank gespeichert sind.
   * @returns RoleItemPercentageDTO OK
   * @throws ApiError
   */
  public static getRoleItemPercentages(): CancelablePromise<Array<RoleItemPercentageDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/services/percentages',
    });
  }

  /**
   * Neuen Rollen-Artikel-Prozentsatz hinzufügen
   * Fügt einen neuen Rollen-Artikel-Prozentsatz hinzu.
   * @returns RoleItemPercentageDTO OK
   * @throws ApiError
   */
  public static addRoleItemPercentage({
    requestBody,
    request,
  }: {
    requestBody: RoleItemPercentagePostRequest,
    /**
     * Die Anforderungsdaten für den neuen Rollen-Artikel-Prozentsatz.
     */
    request?: any,
  }): CancelablePromise<RoleItemPercentageDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/services/percentages',
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
