
import Vue from 'vue'
import { Bus } from '@/main'
import RelationTable from '@/components/RelationTable.vue'
import StripConstructionRelationController from '@/controllers/StripConstructionController'
import StripConstructionRelationModel from '@/models/StripConstructionRelationModel'
import PlantModel from '@/models/PlantModel'
import StripModel from '@/models/StripModel'

export default Vue.extend({
  components: {
    RelationTable
  },
  data: () => ({
    dialog: false,
    item: new StripModel(),
    stripConstructionController: new StripConstructionRelationController()
  }),
  created () {
    Bus.$on('open-strip-construction-dialog', (item: StripModel) => {
      this.item = JSON.parse(JSON.stringify(item))
      this.dialog = true
      this.stripConstructionController.load(this.item.id)
    })
  },
  methods: {
    nextItem () {
      const nextIndex = this.$store.state.strips.findIndex((item: StripModel) => {
        return item.id === this.item.id
      })
      if (nextIndex + 1 >= this.$store.state.strips.length) { return }
      this.setItem(this.$store.state.strips[nextIndex + 1])
    },
    previousItem () {
      const ind = this.$store.state.strips.findIndex((item: StripModel) => {
        return item.id === this.item.id
      })
      if (ind <= 0) { return }
      this.setItem(this.$store.state.strips[ind - 1])
    },
    setItem (item: StripModel) {
      this.item = item
      // Trigger the Bus
      this.stripConstructionController.load(item.id)
    }
  }
})
