import Vue from 'vue'
import axios from 'axios'
import { Module } from 'vuex'
import SpecificationModel from '@/models/SpecificationModel'

const specificationDetail: Module<any, any> = {
  namespaced: true,
  state: () => ({
    specification: new SpecificationModel()
  }),
  mutations: {
    setSpecification (state, specification: SpecificationModel) {
      state.specification = specification
    }
  },
  actions: {
    loadSpecification ({ commit }, id) {
      return new Promise<void>(resolve => {
        Vue.axios
          .get(`macrolib/specifications/${id}`)
          .then(result => {
            commit('setSpecification', result.data)
            resolve()
          })
      })
    },
    resetSpecification ({ commit }) {
      return new Promise<void>(resolve => {
        commit('setSpecification', new SpecificationModel())
        resolve()
      })
    },
    createSpecification ({ commit }, { object }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .post('macrolib/specifications', object)
          .then(result => {
            commit('setSpecification', result.data)
            resolve()
          })
      })
    },
    deleteSpecification ({ commit }, { id }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .delete(`macrolib/specifications/${id}`)
          .then(result => {
            resolve()
          })
      })
    },
    updateSpecification ({ commit }, { id, object }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .put(`macrolib/specifications/${id}`, object)
          .then(result => {
            commit('setSpecification', result.data)
            resolve()
          })
      })
    },
    patchSpecification ({ commit }, { id, object }) {
      return new Promise<void>(resolve => {
        Vue.axios
          .patch(`macrolib/specifications/${id}`, object)
          .then(result => {
            commit('setSpecification', result.data)
            resolve()
          })
      })
    }
  }
}
export default specificationDetail
